import React, { useEffect } from 'react'
import {Button, Card, Col, Form, Input, message, Row, Select, Spin} from "antd";
import { useState } from "react";
import { useSwitch } from '../pages/SwitchContext';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useLocation } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../helpers";
import {getAPIurl, getInitialValuesFormular, SaveIcon, updateDataContent} from '../helpers/formHelpers';
import TextArea from 'antd/lib/input/TextArea';
const { Option } = Select;
let dataContainer;
let dataContent;
let enumerationValuesContent;
let updateDataContentValue;

const putBody = {};
const TextbausteineEdit = () => {
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const location = useLocation();
    const { state } = location;

    const [saveIcon, setSaveIcon] = useState(false);
    const [saveIcon1, setSaveIcon1] = useState(false);
    const { id } = useParams(); // Get the activeKey from the URL
    const [formFormular] = Form.useForm();
    const [selectedOption, setSelectedOption] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Zustand für den Ladezustand



    const handleSelectChange = (value) => {
        setSelectedOption(value);
    }

    const fetchData = async () => {

        try {

            const response = await fetch(`${getAPIurl()}/textbausteines/${id}?populate=deep`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            dataContainer = await response.json();
            dataContent = dataContainer.data.attributes;
            // Get enumerationValues
            const enumerationValuesResponse = await fetch(
                `${getAPIurl()}/textbausteine/enumvalues`,
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            enumerationValuesContent = await enumerationValuesResponse.json();

        } catch (error) {
            message.error("Error while fetching enumerationValues!");
            console.error(error);
        }
    };


    useEffect(() => {
        fetchData().then(() => {
            setIsLoading(false);
        });
    }, []);

    return (
        <>
            {!isLoading && dataContent && enumerationValuesContent && (
            <div style={{ padding: '20px' }} className={`lg:w-[84%] w-[90%] ml-auto mr-[2%] rounded-[30px]  ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
                {saveIcon1 && <SaveIcon saveIcon={saveIcon} />}
                <h2 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'} mb-[15px]`}>Textbausteine ​​bearbeiten</h2>
                <Link
                    to="/textbausteine"
                    type="primary"
                    icon={<ArrowLeftOutlined />} className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  mt-[10px] p-[10px] rounded-full LinkHover`}>
                    Zurück zur Übersicht
                </Link>
                <Card className={`mt-[30px] border-none ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
                    <Form
                        form={formFormular}
                        layout="vertical"
                        initialValues={getInitialValuesFormular(dataContent)}
                        onBlur={(e) => {
                            // Hier wird das onBlur-Ereignis für das gesamte Formular abgefangen

                            if (e.target.id == "Kategorie") {
                                updateDataContentValue = selectedOption;
                            } else {
                                updateDataContentValue = e.target.value;
                            }
                            updateDataContent(e.target.id, updateDataContentValue, dataContent);
                            putBody.data = dataContent;
                            setSaveIcon(true);
                            setSaveIcon1(true)
                            fetch(`${getAPIurl()}/textbausteines/${id}/`, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${getToken()}`,
                                },
                                body: JSON.stringify(putBody),
                            })
                                .then((response) => {
                                    if (!response.ok) {
                                        throw new Error('Failed to update data.');
                                    }
                                    return response.json();

                                })
                                .then((responseData) => {
                                    // Handle the response data after the update
                                    //console.log('Updated data:', responseData);
                                    // Setze das Speicher-Icon nach X Millisekunden zurück
                                    setTimeout(() => { setSaveIcon(false); }, 300);
                                    setTimeout(() => {
                                        setSaveIcon1(false);
                                    }, 3000);
                                })
                                .catch((error) => {
                                    console.error('Error:', error);
                                });

                        }}
                    >
                        <Row className="flex-col items-center">
                            <Col className="w-[300px] sm:w-[400px]">
                                <Form.Item
                                    label="Textbaustein"
                                    name="Textbaustein_Satz"
                                    className={` ${isSwitchOn ? 'formprofile1' : 'formprofile'}`}
                                >
                                    <TextArea placeholder="Textbaustein" className="inputProfile" />
                                </Form.Item>
                            </Col>
                            <Col className="w-[300px] sm:w-[400px]">
                                <Form.Item
                                    label="Kategorie"
                                    name="Kategorie"
                                    className={` ${isSwitchOn ? 'formprofile1' : 'formprofile'}`}
                                >
                                    <Select onChange={handleSelectChange}>
                                        {enumerationValuesContent.Kategorie.map((option, index) => (
                                            <Option key={index} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </div >
            )}
        </>
    )
}

export default TextbausteineEdit;
