import React, {useEffect, useState, useRef, useContext} from "react";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {downloadRenderFile, getAPIurl, LoadingScreen} from "../../helpers/formHelpers";
import { useSwitch } from '../../pages/SwitchContext';
import { getToken } from "../../helpers";
import { LocalizationContext, Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import {MoreActionsPopover, ToolbarProps, ToolbarSlot} from '@react-pdf-viewer/toolbar';
import de_DE from '../../locales/de_DE.json';


import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {thumbnailPlugin} from "@react-pdf-viewer/thumbnail";
import {UserKonfigurationContext} from "../AuthProvider/AuthProvider";

// Set height of iframe depending on screen-size
const handleDocumentLoad = () => {
    var container = document.getElementById("container");
    var iFrameHeight = window.innerHeight-400;
    if(iFrameHeight < 700) {
        iFrameHeight = 700;
    }
    container.style.height = iFrameHeight+"px";
    var iFrame = container.querySelector("div:first-child");
    if (iFrame) {
        iFrame.style.height = (iFrameHeight-50)+"px";
    }
}

const handlePageChange = (e: PageChangeEvent) => {
    localStorage.setItem('vorschau-current-page', `${e.currentPage}`);
}
const getInitalPage = () => {
    return localStorage.getItem('vorschau-current-page') ? parseInt(localStorage.getItem('vorschau-current-page'), 10) : 0;

}

const Vorschau = () => {
    const [loading, setLoading] = useState(false);
    const { auftragID, gutachtenID } = useParams();
    const { isSwitchOn } = useSwitch();
    const [pdfDataUrl, setPdfDataUrl] = useState(null); // Changed to URL
    const userKonfiguration = useContext(UserKonfigurationContext);

    const iframeRef = useRef(null);


    useEffect(() => {
     if(userKonfiguration !== undefined) {
            loadFile();
        }
    }, [userKonfiguration]); // Empty dependency array ensures the effect runs once on component mount


    const loadFile = async () => {
        setLoading(true);

        try {
            let cust_name = "";
            if(userKonfiguration && userKonfiguration.CustomPreview){
                cust_name = userKonfiguration.CustomPreview;
            }
            const vorschau_url = `${getAPIurl()}/helper/render-docx/Gutachten/${auftragID}/${gutachtenID}?file_type=pdf&cust_name=${cust_name}`;
            const response = await fetch(vorschau_url, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob); // Convert blob to URL
            setPdfDataUrl(url);
            setLoading(false);
        } catch (error) {
            console.error('Error downloading file:', error);
            setLoading(false);
        }
    };

    const downloadRenderFilePreview = async (
        type,
        file_type,
        auftragID,
        gutachtenIDorAnforderung
    ) => {
        try {
            setLoading(true);
            downloadRenderFile("Gutachten", "docx", auftragID, gutachtenID).then(r => {
                setLoading(false);
            })

        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const containerStyle = {
        width: '100%', // Make the container take up 100% of the width
        height: '700px', // Set a fixed height for the iframe
        overflow: 'hidden', // Hide scrollbars of the iframe
    };

    /* All PDF plugins */

    /* Custom toolbar: https://react-pdf-viewer.dev/plugins/toolbar/ */
    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
        <Toolbar>
            {(toolbarSlot: ToolbarSlot) => {
                const {
                    CurrentPageInput,
                    Download,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    Print,
                    ShowSearchPopover,
                    SwitchTheme,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                } = toolbarSlot;

                return (
                    <div className="rpv-toolbar" role="toolbar" aria-orientation="horizontal">
                        <div className="rpv-toolbar__left">
                            <div className="rpv-toolbar__item">
                                <ShowSearchPopover/>
                            </div>
                            <div className="rpv-core__display--hidden rpv-core__display--block-small">
                                <div className="rpv-toolbar__item">
                                    <GoToPreviousPage/>
                                </div>
                            </div>
                            <div className="rpv-toolbar__item">
                                <CurrentPageInput/>{' '}
                                <span className="rpv-toolbar__label">
                                    / <NumberOfPages/>
                                </span>
                            </div>
                            <div className="rpv-core__display--hidden rpv-core__display--block-small">
                                <div className="rpv-toolbar__item">
                                    <GoToNextPage/>
                                </div>
                            </div>
                        </div>
                        <div className="rpv-toolbar__center">
                            <div className="rpv-toolbar__item">
                                <ZoomOut/>
                            </div>
                            <div className="rpv-core__display--hidden rpv-core__display--block-small">
                            <div className="rpv-toolbar__item">
                                    <Zoom/>
                                </div>
                            </div>
                            <div className="rpv-toolbar__item">
                                <ZoomIn/>
                            </div>
                        </div>
                        <div className="rpv-toolbar__right">
                            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
                                <div className="rpv-toolbar__item">
                                    <Download/>
                                </div>
                            </div>
                            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
                                <div className="rpv-toolbar__item">
                                    <SwitchTheme/>
                                </div>
                            </div>
                            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
                                <div className="rpv-toolbar__item">
                                    <EnterFullScreen/>
                                </div>
                            </div>
                            <div className="rpv-core__display--hidden rpv-core__display--block-medium">
                                <div className="rpv-toolbar__item">
                                    <Print/>
                                </div>
                            </div>
                            <div className="rpv-toolbar__item">
                                <MoreActionsPopover toolbarSlot={toolbarSlot}/>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) => [
            // Remove the attachments tab (\`defaultTabs[2]\`)
            defaultTabs[0], // Bookmarks tab
                defaultTabs[1], // Thumbnails tab
            ],
            thumbnailPlugin: {
                thumbnailWidth: 200,
            },
        }
    );

    /* Localization: https://react-pdf-viewer.dev/examples/use-a-different-language/ */
    const [l10n, setL10n] = React.useState(de_DE);
    const localizationContext = {l10n, setL10n};


    return (
        <div className={`${isSwitchOn ? 'bg-black' : 'bg-white'} mr-[15px] rounded-r-[30px] pt-[15px]`}>
            {loading && <LoadingScreen/>}
            <div className="flex items-center pt-[10px] mb-[10px] flex-wrap gap-[15px] justify-center align-center">
                <button className={`${isSwitchOn ? 'lastButtons1' : 'lastButtons'}`} onClick={loadFile}
                        style={{cursor: 'pointer'}}><ReloadOutlined/> Aktualisieren
                </button>
                &nbsp;
                <button className={`${isSwitchOn ? 'lastButtons1' : 'lastButtons'}`}
                        onClick={() => downloadRenderFilePreview("Gutachten", "docx", auftragID, gutachtenID)}
                        style={{cursor: 'pointer'}}><DownloadOutlined/> Word-Datei runterladen
                </button>
                &nbsp;
                <button className={`${isSwitchOn ? 'lastButtons1' : 'lastButtons'}`}
                        onClick={() => window.open(`/gutachten/${auftragID}/${gutachtenID}/vorschau`, '_blank')}
                        style={{cursor: 'pointer'}}><DownloadOutlined/> Vorschau in einem neuen Tab öffnen
                </button>
            </div>
            <div id="container" className={`fileReader ${isSwitchOn ? 'fileReader1' : ""}`} style={containerStyle}>
                {pdfDataUrl && (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
                        <div style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            height: '650px',
                            width: '90%',
                            margin: "0 auto",
                        }}>
                            <LocalizationContext.Provider value={localizationContext}>
                                <Viewer
                                    fileUrl={pdfDataUrl}
                                    onPageChange={handlePageChange}
                                    initialPage={getInitalPage()}
                                    onDocumentLoad={handleDocumentLoad}
                                    localization={de_DE}
                                    defaultScale={1}
                                    plugins={[
                                        defaultLayoutPluginInstance
                                    ]}
                                />
                            </LocalizationContext.Provider>
                        </div>
                    </Worker>
                )}
            </div>
        </div>
    );
};

export default Vorschau;
