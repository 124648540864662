import React, {useEffect} from "react";
import {Button, Card, Col, Form, Input, message, Row, Select, Spin} from "antd";

import { useAuthContext } from "../../context/AuthContext";
import { useState } from "react";
import { getToken } from "../../helpers";
import { useSwitch } from '../../pages/SwitchContext';
import axios from 'axios';
import {getAPIurl, validatePasswordSecurity} from "../../helpers/formHelpers";
const { Option } = Select;

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const { user, isLoading, setUser } = useAuthContext();
  const { isSwitchOn, toggleSwitch } = useSwitch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [enumerationValuesContent, setEnumerationValuesContent] = useState(null);
  const [dataContent, setDataContent] = useState(null);
  const putBody = {};

  useEffect(() => {
    fetchProfile();
  }, []);

  if (isLoading) {
    return <Spin size="large" />;
  }



  const fetchProfile = async () => {
    try {
      const response = await fetch(`${getAPIurl()}/konfigurations/1`, { // &filters[role][$eq]=${userRole}
        headers: {
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const dataContainer = await response.json();
      setDataContent(dataContainer.data.attributes);

      const enumerationValues = await fetch(`${getAPIurl()}/konfiguration/enumvalues`, { // &filters[role][$eq]=${userRole}
        headers: {
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setEnumerationValuesContent(await enumerationValues.json());
     // console.log(enumerationValuesContent);

    } catch (error) {
      console.log(error);
      message.error("Error while fetching gutachten!" + error);
    }

  }


  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  // Function that updates the value of a specific key in JSON data
  /*const updateValueById = (data, id, newValue) => {
    // Check if the provided id key exists in the JSON data object
    if (data.hasOwnProperty(id)) {
      // Update the JSON data object by replacing the value for the provided key
      data[id] = newValue;
      //console.log(`Value for key ${id} updated successfully.`);
    } else {
      console.error(`Key ${id} not found.`);
    }
  };*/

  // Save konfiguraion
  const updateKonfiguration = (id, newValue) => {
    setLoading(true);
    dataContent[id] = newValue;
    putBody.data = dataContent;
    try {
      const response =  fetch(`${getAPIurl()}/konfigurations/1`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(putBody),
      });
      // const responseData =  response.json();
      message.success("Daten gespeichert!");
    } catch (error) {
      console.error(error);
      message.error("Daten konnten nicht gespeichert werden!");
    } finally {
      setLoading(false);
    }
  }

  const handleChangePassword = async () => {
    const validationError = validatePasswordSecurity(newPassword);
    if (validationError) {
      message.error(validationError);
      return;
    }

    if (newPassword !== newPasswordConfirmation) {
      message.error("Passwörter sind unterschiedlich!");
      return;
    }

    try {
      const response = fetch(`${getAPIurl()}/auth/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify({
          currentPassword: currentPassword,
          password: newPassword,
          passwordConfirmation: newPasswordConfirmation,
        })
      })
          .then(response => response.json())
          .then(data => {
            if(data.error){
              if(data.error.message === "The provided current password is invalid"){
                message.error("Altes Passwort ist nicht korrekt.");
              } else {
                console.log(data.error);
                message.error("Passwort konnte nicht geändert werden.");
              }
            } else {
              message.success("Passwort wurde erfolgreich geändert!");
            }
          })
          .catch(error => {
            console.error('Error changing password', error);
            message.error("Passwort konnte nicht geändert werden.");
          });
    } catch (error) {
      console.log(error);
      message.error('Passwort konnte nicht geändert werden!');
    }
  };


  return (
      <div>
    {dataContent && enumerationValuesContent && (
      <Card className={`profile_page_card  ${isSwitchOn ? 'bg-black' : 'bg-white'}  mr-[150px] ml-[150px] rounded-[30px] pt-[15px]`}>
        <Form
          layout="vertical"
          initialValues={{
            username: user?.username,
            email: user?.email,
            Deckblatt: dataContent.Deckblatt,
            KopfFusszeile: dataContent.KopfFusszeile,
          }}
          onBlur={ (e) => {
            if(e.target.type != "search") { // Dropdowns for Konfiguration
              /* Removed due to security

              updateValueById(user, e.target.id, e.target.value);
              setLoading(true);
              try {
                const response =  fetch(`${getAPIurl()}/users/${user.id}`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                  },
                  body: JSON.stringify(user),
                });
                // const responseData =  response.json();
                message.success("Daten gespeichert!");
              } catch (error) {
                console.error(error);
                message.error("Daten konnten nicht gespeichert werden!");
              } finally {
                setLoading(false);
              }*/
            } else {
              updateKonfiguration(e.target.id, selectedOption);
            }
          }
          }
        >
          <Row className="flex-col items-center">
            {/* Removed due to security
            <Col className="w-[300px] sm:w-[400px]">
              <Form.Item
                label="Name"
                name="username"
                className={` ${isSwitchOn ? 'formprofile1' : 'formprofile'}`}
                rules={[
                  {
                    required: true,
                    message: "Name ist erforderlich!",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Name" className="inputProfile" />
              </Form.Item>
            </Col>
            <Col className="w-[300px] sm:w-[400px]">
              <Form.Item
                label="Email"
                name="email"
                className={` ${isSwitchOn ? 'formprofile1' : 'formprofile'}`}
                rules={[
                  {
                    required: true,
                    message: "Email ist erforderlich!",
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email" className="inputProfile" />
              </Form.Item>
            </Col> */}
            <Col className="w-[300px] sm:w-[400px]">
              <Form.Item
                  label="Deckblatt"
                  name="Deckblatt"
                  className={` ${isSwitchOn ? 'formprofile1' : 'formprofile'}`}
              >
                <Select onChange={handleSelectChange}>
                  {enumerationValuesContent.Deckblatt.map((option, index) => (
                      <Option key={index} value={option}>
                        {option}
                      </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="w-[300px] sm:w-[400px]">
              <Form.Item
                  label="Kopf-/Fußzeile"
                  name="KopfFusszeile"
                  className={` ${isSwitchOn ? 'formprofile1' : 'formprofile'}`}
              >
                <Select onChange={handleSelectChange}>
                  {enumerationValuesContent.KopfFusszeile.map((option, index) => (
                      <Option key={index} value={option}>
                        {option}
                      </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form
            layout="vertical">
          <Row className="flex-col items-center">
            <Col className={`w-[300px] sm:w-[400px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>
              <h3 class={"font-bold text-[20px] generalText text-black"}>Passwort ändern</h3>
            </Col>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Aktuelles Passwort"
              className="passwordInput"
            />
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Neues Passwort"
              className="passwordInput"
            />
            <input
              type="password"
              value={newPasswordConfirmation}
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
              placeholder="Neues Passwort bestätigen"
              className="passwordInput"
            />
          </Row>
          <Button
            className="profile_save_btn mt-[20px] ml-auto mr-auto bg-black rounded-[10px]"
            htmlType="submit"
            type="primary"
            size="large"
            onClick={handleChangePassword}
          >
            {loading ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              "Passwort ändern"
            )}
          </Button>
        </Form>
      </Card>
      )}
      </div>
  );
};

export default Profile;
