import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {message, Modal, Space, Table} from "antd";
import { getToken } from "../helpers";
import Sidebar from "../components/sidebar";
import useScreenSize from "../hooks/useScreenSize";
import { useSwitch } from "../pages/SwitchContext";
import axios from "axios";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import {getAPIurl} from "../helpers/formHelpers";


let navigate;
let sideOptions = [
    { value: "Allgemeine Angaben" },
    { value: "Lage" },
    { value: "Grundstück" },
    { value: "Gebäude & Außenanlagen" },
    { value: "Wertermittlung" },
];



const Textbausteine = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [search, setSearch] = useState("");
    const [tableData, setTableData] = useState([])
    const [selected, setSelected] = useState("")
    const [updateTrigger, setUpdateTrigger] = useState(false);
    navigate = useNavigate();


    async function deleteTextbausteine(deleteID) {
        Modal.confirm({
            title: 'Textbaustein löschen',
            content: 'Möchten Sie diesen Textbaustein wirklich löschen?',
            onOk() {
                deleteTextbausteineConfirmed(deleteID);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    }

    async function deleteTextbausteineConfirmed(deleteID) {
        try {
            setUpdateTrigger(false);
            const response = await fetch(`${getAPIurl()}/textbausteines/${deleteID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            if (response.ok) {
                setUpdateTrigger(true);
                console.log(`Eintrag mit ID ${deleteID} wurde erfolgreich gelöscht.`);

            } else {
                console.error(`Fehler beim Löschen des Eintrags: ${response.status} - ${response.statusText}`);
            }

        } catch (error) {
            console.error('Fehler beim Löschen des Eintrags:', error);
        }
    }
    const columns = [
        {
            title: "Textbaustein",
            dataIndex: "Textbaustein_Satz",
            key: "Textbaustein_Satz",
        },
        {
            title: "Kategorie",
            dataIndex: "Kategorie",
            key: "Kategorie",
        },

        {
            title: "",
            key: "actions",
            render: (text, record) => (
                <Space size="middle">
                    <RiDeleteBin6Fill className="text-[22px] mb-[5px] mr-[-10px] text-[#FF2E3B] cursor-pointer" onClick={() => deleteTextbausteine(record.id)} />
                    <Link state={{ record }} to={`/textbausteine/${record.id}`} className="border-none" >
                        <MdEdit className={`text-[22px] ${isSwitchOn ? 'textwhite' : 'text-black'} mb-[5px] mr-[-10px] text-[#FF2E3B]`} />
                    </Link>
                </Space>
            ),
        },
    ];



    const fetchTextbausteine = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(
                `${getAPIurl()}/textbausteines?populate=deep&filters[origin][$eq]=custom`,
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            const dataContainer = await response.json();
            let tableDatav = dataContainer.data.map((item, index) => {
                return {
                    key: index,
                    id: item.id,
                    Textbaustein_Satz: item.attributes?.Textbaustein_Satz,
                    Kategorie: item.attributes?.Kategorie,
                };
            });
            setTableData(tableDatav)
        } catch (error) {
            console.log(error);
            message.error("Error while fetching Aufträge!");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (search !== "" || selected !== "") {
            Search();

        } else {
            fetchTextbausteine().then();
        }
    }, [search, selected]);

    useEffect(() => {
            fetchTextbausteine().then();

    }, [updateTrigger]);

    useEffect(() => {
        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }
    }, [isDesktopView]);
    const Search = () => {
        let url
        if (selected == "Allgemeine Angaben") {
            url = `${getAPIurl()}/textbausteines?filters[origin][$eq]=custom&filters[Kategorie][$eq]=Auftragsbeschreibung`

        } else if (selected == "Lage") {
            url = `${getAPIurl()}/textbausteines?filters[origin][$eq]=custom&sort[0]=Kategorie:asc&pagination[page]=1&filters[$or][0][Kategorie][$eq]=Stadtzentrum&filters[$or][1][Kategorie][$eq]=Geschäfte/Einrichtungen&filters[$or][2][Kategorie][$eq]=Freizeit/Naherholung&filters[$or][3][Kategorie][$eq]=Art%20des%20Gebiets&filters[$or][4][Kategorie][$eq]=Immissionen/Beeinträchtigungen&filters[$or][5][Kategorie][$eq]=Lage
            `
        } else if (search !== "") {
            url = `${getAPIurl()}/textbausteines?filters[origin][$eq]=custom&sort[0]=Kategorie:asc&pagination[page]=1&filters[$or][0][Kategorie][$contains]=${search}&filters[$or][1][Textbaustein_Satz][$contains]=${search}`
        } else if (selected == "Grundstück") {
            url = `${getAPIurl()}/textbausteines?filters[origin][$eq]=custom&sort[0]=Kategorie:asc&pagination[page]=1&filters[$or][0][Kategorie][$eq]=Straßenart&filters[$or][1][Kategorie][$eq]=Straßenausbau&filters[$or][2][Kategorie][$eq]=Anschlüsse&filters[$or][3][Kategorie][$eq]=Grenzverhältnisse&filters[$or][4][Kategorie][$eq]=Baugrund/Grundwasser&filters[$or][5][Kategorie][$eq]=Belastung%20Grundbuch&filters[$or][6][Kategorie][$eq]=Bebauungsplan&filters[$or][7][Kategorie][$eq]=Grundstück`

        } else if (selected == "Gebäude & Außenanlagen") {
            url = `${getAPIurl()}/textbausteines?filters[origin][$eq]=custom&sort[0]=Kategorie:asc&pagination[page]=1&filters[$or][0][Kategorie][$eq]=Sanierung/Modernisierung&filters[$or][1][Kategorie][$eq]=Nutzungsart&filters[$or][2][Kategorie][$eq]=Außenwände&filters[$or][3][Kategorie][$eq]=Fassade&filters[$or][4][Kategorie][$eq]=Innenwände&filters[$or][5][Kategorie][$eq]=Geschossdecken&filters[$or][6][Kategorie][$eq]=Treppenhaus&filters[$or][7][Kategorie][$eq]=Sonstiges%20Gebäude&filters[$or][8][Kategorie][$eq]=Haus-/Wohnungstüren&filters[$or][9][Kategorie][$eq]=Innentüren&filters[$or][10][Kategorie][$eq]=Fenster,%20Balkon-/Terrassentüren&filters[$or][11][Kategorie][$eq]=Fußbodenbeläge&filters[$or][12][Kategorie][$eq]=Wandbeläge&filters[$or][13][Kategorie][$eq]=Deckenbeläge&filters[$or][14][Kategorie][$eq]=Küchen&filters[$or][15][Kategorie][$eq]=Sanitäre%20Anlagen&filters[$or][16][Kategorie][$eq]=Sonstiges%20Ausstattung&filters[$or][17][Kategorie][$eq]=Sonstiges%20(Haustechnik)&filters[$or][18][Kategorie][$eq]=Besondere%20Bauteile&filters[$or][19][Kategorie][$eq]=Besondere%20Einrichtungen&filters[$or][20][Kategorie][$eq]=Beschreibung%20Außenanlagen&filters[$or][21][Kategorie][$eq]=Zuwegung&filters[$or][22][Kategorie][$eq]=Einfriedung&filters[$or][23][Kategorie][$eq]=Garagen&filters[$or][24][Kategorie][$eq]=Stellplätze&filters[$or][25][Kategorie][$eq]=Grundrissgestaltung&filters[$or][26][Kategorie][$eq]=Bauschäden%20und%20Baumängel&filters[$or][27][Kategorie][$eq]=Miete&filters[$or][28][Kategorie][$eq]=Kosten&filters[$or][29][Kategorie][$eq]=Gebäude%20und%20Außenanlage&filters[$or][30][Kategorie][$eq]=Miete%20und%20Kosten`

        } else if (selected == "Wertermittlung") {
            url = `${getAPIurl()}/textbausteines?filters[origin][$eq]=custom&sort[0]=Kategorie:asc&pagination[page]=1&filters[$or][0][Kategorie][$eq]=Bodenwert%20Merkmale&filters[$or][1][Kategorie][$eq]=Bodenwert&filters[$or][2][Kategorie][$eq]=Vergleichswert%20Anpassungen&filters[$or][3][Kategorie][$eq]=Ertragswert%20Rohertrag&filters[$or][4][Kategorie][$eq]=Ertragswert%20Bewirtschaftungskosten&filters[$or][5][Kategorie][$eq]=Ertragswert%20Liegenschaftszinssatz&filters[$or][6][Kategorie][$eq]=Ertragswert%20Gesamtnutzungsdauer&filters[$or][7][Kategorie][$eq]=Ertragswert%20Restnutzungsdauer&filters[$or][8][Kategorie][$eq]=Sachwert%20NHK&filters[$or][9][Kategorie][$eq]=Sachwert%20Baupreisindex&filters[$or][10][Kategorie][$eq]=Sachwert%20Gesamtnutzungsdauer&filters[$or][11][Kategorie][$eq]=Sachwert%20Restnutzungsdauer&filters[$or][12][Kategorie][$eq]=Sachwert%20Nebengebäude&filters[$or][13][Kategorie][$eq]=Sachwert%20Bauteile%20und%20Einrichtungen&filters[$or][14][Kategorie][$eq]=Sachwert%20Außenanlagen&filters[$or][15][Kategorie][$eq]=Sachwert%20Marktanpassung&filters[$or][16][Kategorie][$eq]=Besondere%20objektspezifische%20Grundstücksmerkmale%20(BoG)&filters[$or][17][Kategorie][$eq]=Vermietbarkeit&filters[$or][18][Kategorie][$eq]=Verkäuflichkeit`

        }
        axios
            .get(url,

                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then(async (response) => {
                const dataContainer = response.data;
                let tableDataS = await dataContainer?.data?.map((item, index) => {
                    return {
                        key: index,
                        id: item.id,
                        Textbaustein_Satz: item.attributes?.Textbaustein_Satz,
                        Kategorie: item.attributes?.Kategorie,
                    };
                });
                setTableData(tableDataS)
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const createTextbaustein = async () => {
        try {
                // Daten für den neuen Eintrag
                const newData = {
                    data: {
                        origin: "custom",
                    },
                };
                // Sende einen POST-Request an deine Strapi-API, um den neuen Eintrag zu erstellen
                const response = await fetch(`${getAPIurl()}/textbausteines`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                    body: JSON.stringify(newData),
                });

                if (response.ok) {
                    const responseBody = await response.json();
                    const newUrl = `/textbausteine/${responseBody.data.id}`;
                    navigate(newUrl);
                } else {
                    // Behandle den Fall, wenn der POST-Request fehlschlägt
                    console.error("Fehler beim Erstellen des Eintrags.");
                }

        } catch (error) {
            // Behandle andere Fehler, die auftreten könnten
            console.error("Fehler beim Erstellen des Eintrags:", error);
        }
    };



    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };


    return (
        <div class="w-full">

            <div
                className={`flex flex-row gap-[30px]  mr-[40px]  mb-[40px] ${!isDesktopView ? "ml-[40px]" : ""
                    }`}
            >
                {vis ? (
                    <Sidebar
                        sideOptions={sideOptions}
                        handleCreate={createTextbaustein}
                        head="Textbaustein erstellen"
                        pageTittle="Textbausteine"
                        selected={selected}
                        setSelected={setSelected}
                        vis={vis}
                        onVisibilityChange={handleVisibilityChange}
                    />
                ) : (
                    ""
                )}
                <div
                    style={{ overflowX: "hidden" }}
                    className="rounded-[30px]   w-full"
                >
                    {" "}
                    <div
                        className={` relative w-[80%] sm:w-[489px] h-[54px]   ${isSwitchOn ? "bg-black" : "bg-white"
                            } flex justify-between rounded-[30px] shadow px-[20px] items-center mb-[17px]`}
                    >
                        <img src={require("../assets/search.png")} className=" " alt="" />
                        <input
                            value={search}
                            onChange={(e) => {
                                setSearch(e?.target?.value)
                            }}
                            type="text"
                            className={` w-[100%] h-full text-neutral-700 ${isSwitchOn ? "text-white" : "text-neutral-700"
                                } focus:border-none outline-none shadow-none rounded pl-[10px] ${isSwitchOn ? "bgdark" : "bgwhite"
                                }`}
                            placeholder="Suchen..."
                        />
                    </div>
                    <div
                        className={`${!isDesktopView ? "visible  cursor-pointer" : "hidden"
                            } text-white `}
                        onClick={() => {
                            setVis(!vis);
                        }}
                    >
                        <button className="menuBtn">Menu</button>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        responsive
                        pagination={{
                            pageSizeOptions: [20, 50, 100, 150],
                            defaultPageSize: 20,
                            locale: {items_per_page: "Einträge / Seite"}
                        }}
                        style={{
                            background: "white",
                            overflowX: "auto",
                            borderRadius: "30px",

                        }}
                        className={`md:p-[40px] p-[20px]  ${isSwitchOn ? 'bgdark' : 'bgwhite'}  ${isSwitchOn ? 'tableComtainer' : 'tableComtainerBlack'}`}
                    />
                </div>
            </div>{" "}
        </div>
    )
}

export default Textbausteine
