import React, { createContext, useState, useEffect } from "react";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import { message } from "antd";
import { getToken, removeToken } from "../../helpers";
import { getAPIurl } from "../../helpers/formHelpers";

// Create UserKonfigurationContext
const UserKonfigurationContext = createContext();

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [userKonfiguration, setUserKonfiguration] = useState(); // State for user configuration
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useAuthContext();

  const authToken = getToken();

  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);

    try {
      const response = await fetch(`${getAPIurl()}/users/me?populate=role`, {
        headers: { Authorization: `${process.env.REACT_APP_BEARER} ${token}` },
      });
      const data = await response.json();
      if (data.data == null && data.error) {
        removeToken();
        setUser(undefined);
        window.location.href = '/';
      } else {
        setUserData(data);
        const response_konf = await fetch(`${getAPIurl()}/konfigurations/1`, {
          headers: { Authorization: `${process.env.REACT_APP_BEARER} ${token}` },
        });
        const data_konf = await response_konf.json();
        setUserKonfiguration(data_konf.data.attributes); // Setting the user configuration
      }
    } catch (error) {
      console.error(error);
      message.error("Error While Getting Logged In User Details");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
      <AuthContext.Provider value={{ user: userData, setUser: handleUser, isLoading }}>
        {/* Provide both AuthContext and UserKonfigurationContext */}
        <UserKonfigurationContext.Provider value={userKonfiguration}>
          {children}
        </UserKonfigurationContext.Provider>
      </AuthContext.Provider>
  );
};

export default AuthProvider;
export { UserKonfigurationContext }; // Export UserKonfigurationContext for use in other components
