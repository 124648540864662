import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Alert, Button, message, Modal, Space, Table} from "antd";
import { getToken, setAuftrag } from "../helpers";
import {createAuftragExecute, getAPIurl, postGutachten} from "../helpers/formHelpers";
import Sidebar from "../components/sidebar";
import useScreenSize from "../hooks/useScreenSize";
import { useSwitch } from "../pages/SwitchContext";
import axios from "axios";
import {MdContentCopy, MdEdit} from "react-icons/md";
import { Link } from "react-router-dom";
import icon1 from '../../src/assets/icon1.png'
import icon2 from '../../src/assets/icon2.png'
import icon3 from '../../src/assets/icon3.png'
import icon4 from '../../src/assets/icon4.png'
import icon5 from '../../src/assets/icon5.png'
import icon6 from '../../src/assets/icon6.png'
import {UserKonfigurationContext} from "../components/AuthProvider/AuthProvider";


let dataContainer;
let navigate;
let sideOptions = [
    {
        value: "Auftrag",
        icon: icon1
    },
    {
        value: "Ortstermin",
        icon: icon2
    },
    {
        value: "Unterlagen",
        icon: icon3
    },
    {
        value: "Bearbeitung",
        icon: icon4
    },
    {
        value: "fertiggestellt",
        icon: icon5
    },
    {
        value: "storniert",
        icon: icon6
    },
];


const AuftragUebersicht = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [orderThisMonth, setOrderThisMonth] = useState(false);
    const [search, setSearch] = useState("");
    const [tableData, setTableData] = useState([])
    const [selected, setSelected] = useState("")
    const userKonfiguration = useContext(UserKonfigurationContext);
    navigate = useNavigate();

    const tableBewertungsObjekteOutput = (gutachten) => {
        let currentBewertungsobjekteValue = "";
        if(gutachten.attributes.AllgAng.AllgAng_Detail.ObjektartV != null && gutachten.attributes.AllgAng.AllgAng_Detail.ObjektartV != "") {
            currentBewertungsobjekteValue += gutachten.attributes.AllgAng.AllgAng_Detail.ObjektartV+", ";
        }
        if(gutachten.attributes.AllgAng.AllgAng_Detail.Strasse != null && gutachten.attributes.AllgAng.AllgAng_Detail.Strasse != "") {
            currentBewertungsobjekteValue += gutachten.attributes.AllgAng.AllgAng_Detail.Strasse+", ";
        }
        if(gutachten.attributes.AllgAng.AllgAng_Detail.NrZusatz != null &&  gutachten.attributes.AllgAng.AllgAng_Detail.NrZusatz != "") {
            currentBewertungsobjekteValue += gutachten.attributes.AllgAng.AllgAng_Detail.NrZusatz+", ";
        }
        if(gutachten.attributes.AllgAng.AllgAng_Detail.PLZ != null &&   gutachten.attributes.AllgAng.AllgAng_Detail.PLZ != "") {
            currentBewertungsobjekteValue += gutachten.attributes.AllgAng.AllgAng_Detail.PLZ+", ";
        }
        if(gutachten.attributes.AllgAng.AllgAng_Detail.Ort != null && gutachten.attributes.AllgAng.AllgAng_Detail.Ort != "") {
            currentBewertungsobjekteValue += gutachten.attributes.AllgAng.AllgAng_Detail.Ort;
        }
        if(gutachten.attributes.bewertungsobjekts.data.length > 0){
            gutachten.attributes.bewertungsobjekts.data.forEach(bewertungsobjekte => {
                if(bewertungsobjekte.attributes.Name_BO) {
                    currentBewertungsobjekteValue += "<br/>"+bewertungsobjekte.attributes.Name_BO;
                }
            });
        }
        return currentBewertungsobjekteValue;
    }

    const fetchKontakte = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(
                `${getAPIurl()}/auftrags?populate=deep&sort=createdAt:desc`,
                {
                    // &filters[role][$eq]=${userRole}
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            );
            dataContainer = await response.json();

            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            // Filter items based on the createdAt property
            const filteredItems = dataContainer.data.filter((item) => {
                // Assuming createdAt is a timestamp; adjust this based on your data structure
                const createdAtDate = new Date(item?.attributes?.createdAt);

                const itemYear = createdAtDate.getFullYear();
                const itemMonth = createdAtDate.getMonth() ;

                // Check if the item's year and month match the current year and month
                return itemYear === currentYear && itemMonth === currentMonth;
            });
            setOrderThisMonth(filteredItems.length);

            let tableDatad = dataContainer.data.map((item, index) => {
                let bewertungsobjekteOutput = "";

                item.attributes.gutachtens.data.forEach(gutachten => {
                    if(bewertungsobjekteOutput != "" && tableBewertungsObjekteOutput(gutachten) != "") {
                        bewertungsobjekteOutput += "<br/><br/>";
                    }
                    bewertungsobjekteOutput += tableBewertungsObjekteOutput(gutachten);
                });
                return {
                    key: index,
                    id: item.id,
                    Auftragsnummer: item.attributes.Auftragsnummer,
                    Jahr: item.attributes.Jahr,
                    Auftrag_StatusV: item.attributes.Auftrag_StatusV,
                    AuftragsartV: item.attributes.AuftragsartV,
                    bewertungsobjekte: bewertungsobjekteOutput,
                };
            });
            setTableData(tableDatad)

        } catch (error) {
            console.log(error);
            message.error("Error while fetching Aufträge!");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (search !== "" || selected !== "") {
            Search();

        } else if(userKonfiguration !== undefined) {
            fetchKontakte().then();
        }
    }, [search, selected, userKonfiguration]);
    useEffect(() => {
        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }


    }, [isDesktopView]);
    const Search = () => {
        let url
        url = getAPIurl()+`/auftrags?sort[0]=id:desc&populate=deep&filters[$or][0][Auftragsnummer][$contains]=${selected ? selected : search}&filters[$or][1][Auftrag_StatusV][$eq]=${selected ? selected : search}`
        axios
            .get(url,

                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then(async (response) => {

                dataContainer = response.data

                let tableDataa = await dataContainer?.data?.map((item, index) => {
                    let bewertungsobjekteOutput = "";
                    item.attributes.gutachtens.data.forEach(gutachten => {
                        if(bewertungsobjekteOutput != "" && tableBewertungsObjekteOutput(gutachten) != "") {
                            bewertungsobjekteOutput += "<br/><br/>";
                        }
                        bewertungsobjekteOutput += tableBewertungsObjekteOutput(gutachten);
                    });
                    return {
                        key: index,
                        id: item.id,
                        Auftragsnummer: item.attributes.Auftragsnummer,
                        Jahr: item.attributes.Jahr,
                        Auftrag_StatusV: item.attributes.Auftrag_StatusV,
                        AuftragsartV: item.attributes.AuftragsartV,
                        bewertungsobjekte: bewertungsobjekteOutput,
                    };
                });
                setTableData(tableDataa)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // Modal for creating a new Auftrag
    const createAuftrag = () => {
        const confirmContent = (
            <div>
                Möchten Sie einen neuen Auftrag erstellen? <br/><br/>
                <Alert
                    description={<span>Diesen Monat bereits erstellte Aufträge: <b>{orderThisMonth}</b></span>}
                    type="info"
                    showIcon
                />
            </div>
        );
        Modal.confirm({
            title: "Auftrag erstellen",
            content: confirmContent,
            onOk: () => {
                createAuftragExecute(userKonfiguration, navigate, 0).then();
            },
            onCancel() {
                console.log("Erstellen abgebrochen");
            },
        });
    }

    // Modal for duplicating a Auftrag
    const duplicateAuftrag = (duplicateID) => {
        const confirmContent = (
            <div>
                Möchten Sie diesen Auftrag duplizieren? <br /><br /> Alle Daten (außer hochgeladene Dokumente) werden kopiert und können bearbeitet werden.<br/><br/>
                <Alert
                    description={<span>Diesen Monat bereits erstellte Aufträge: <b>{orderThisMonth}</b></span>}
                    type="info"
                    showIcon
                />
            </div>
        );
        Modal.confirm({
            title: "Auftrag duplizieren",
            content: confirmContent,
            onOk: () => {
                createAuftragExecute(userKonfiguration, navigate, duplicateID).then();
            },
            onCancel() {
                console.log("Erstellen abgebrochen");
            },
        });
    }



    const columns = [
        {
            title: "Auftrag",
            dataIndex: "Auftragsnummer",
            key: "Auftragsnummer",
        },
        {
            title: "Auftragsart",
            dataIndex: "AuftragsartV",
            key: "AuftragsartV",
        },
        {
            title: "Status",
            dataIndex: "Auftrag_StatusV",
            key: "Auftrag_StatusV",
            render: (text, record) => {
                let backgroundColor;
                let textColor;

                switch (text) {
                    case "Auftrag":
                        backgroundColor = "grey";
                        textColor = "white";
                        break;
                    case "Ortstermin":
                        backgroundColor = "yellow";
                        textColor = "#9d9d5e";
                        break;
                    case "Unterlagen":
                        backgroundColor = "purple";
                        textColor = "white";
                        break;
                    case "Bearbeitung":
                        backgroundColor = "#B5E0FF";
                        textColor = "#28A5FF";
                        break;
                    case "fertiggestellt":
                        backgroundColor = "#96bf96";
                        textColor = "#358325";
                        break;
                    case "storniert":
                        backgroundColor = "#FFB5B5";
                        textColor = "#FF2828";
                        break;
                    default:
                        backgroundColor = "transparent";
                        textColor = "black"; // Default text color
                }

                return (
                    <div style={{ backgroundColor, color: textColor }} className="audtragStuBack">
                        {text}
                    </div>
                );
            },
        },
        {
            title: "Bewertungsobjekte",
            dataIndex: "bewertungsobjekte",
            key: "bewertungsobjekte",
            render: function(html) {
                return <div dangerouslySetInnerHTML={{ __html: html }} />;
            }
        },

        {
            title: "",
            key: "actions",
            render: (text, record) => (
                <Space size="middle">
                    <Link onClick={() => duplicateAuftrag(record.id)} >
                        <MdContentCopy className={`text-[22px] ${isSwitchOn ? 'textwhite' : 'text-black'} mb-[5px] mr-[-10px] text-[#FF2E3B]`} />
                    </Link>
                    <Link onClick={() => {window.location.href="/auftrag/"+record.id}} >
                        <MdEdit className={`text-[22px] ${isSwitchOn ? 'textwhite' : 'text-black'} mb-[5px] mr-[-10px] text-[#FF2E3B]`} />
                    </Link>
                </Space>
            ),
        },
    ];



    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };

    return (
        <div className="w-full">

            <div
                className={`flex flex-row gap-[30px]  mr-[40px]  mb-[80px] ${!isDesktopView ? "ml-[40px]" : ""
                    }`}
            >
                {vis ? (
                    <Sidebar
                        sideOptions={sideOptions}
                        handleCreate={createAuftrag}
                        head="Auftrag erstellen"
                        pageTittle="Aufträge"
                        selected={selected}
                        setSelected={setSelected}
                        vis={vis}
                        onVisibilityChange={handleVisibilityChange}
                    />
                ) : (
                    ""
                )}
                <div
                    style={{ overflowX: "hidden" }}
                    className="rounded-[30px]   w-full"
                >
                    {" "}
                    <div
                        className={` relative w-[80%] sm:w-[489px] h-[54px]   ${isSwitchOn ? "bg-black" : "bg-white"
                            } flex justify-between rounded-[30px] shadow px-[20px] items-center mb-[17px]`}
                    >
                        <img src={require("../assets/search.png")} className=" " alt="" />
                        <input
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                setSelected("")
                            }}
                            type="text"
                            className={` w-[100%] h-full text-neutral-700 ${isSwitchOn ? "text-white" : "text-neutral-700"
                                } focus:border-none outline-none shadow-none rounded pl-[10px] ${isSwitchOn ? "bgdark" : "bgwhite"
                                }`}
                            placeholder="Suchen..."
                        />
                    </div>
                    <div
                        className={`${!isDesktopView ? "visible  cursor-pointer" : "hidden"
                            } text-white `}
                        onClick={() => {
                            setVis(!vis);
                        }}
                    >
                        <button className="menuBtn">Menu</button>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        responsive
                        pagination={{
                            pageSizeOptions: [20, 50, 100, 150],
                            defaultPageSize: 20,
                            locale: {items_per_page: "Einträge / Seite"}
                        }}
                        style={{
                            background: "white",
                            overflowX: "auto",
                            borderRadius: "30px",

                        }}
                    className={`  ${isSwitchOn ? 'bgdark' : 'bgwhite'} tableauftrag  ${isSwitchOn ? 'tableComtainer' : 'tableComtainerBlack'}`}
                    />
                </div>
            </div>{" "}
        </div>
    );
};

export default AuftragUebersicht;
