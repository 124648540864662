import {Button, Modal, Space} from "antd";
import {
  Switch,
} from "antd";
import React, { useState } from "react";
import { CgWebsite } from "react-icons/cg";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { removeToken } from "../../helpers";
import { useSwitch } from "../../pages/SwitchContext";
import '../../Styles/Kontakte.css'

const AppHeader = () => {
  const { user, setUser } = useAuthContext();
  const navigate = useNavigate();
  const { isSwitchOn, toggleSwitch } = useSwitch();
  const [isTooltipVisible, setTooltipVisible] = useState(false);
    const location = useLocation();

  const handleLogout = () => {
    removeToken();
    setUser(undefined);
    navigate("/", { replace: true });
  };



  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };


    const leaveAuftrag = () => {
        const page = location.pathname.split("/");
        if((page[1] == "auftrag" && page[2]) || page[1] == "gutachten") {
            Modal.confirm({
                title: "Auftrag verlassen",
                content: "Möchten Sie den aktuellen Auftrag wirklich verlassen?",
                onOk() {
                    navigate("/auftrag");
                   // window.location.reload();
                },
                onCancel() {
                    console.log("Verlassen abgebrochen");
                },
            });
        } else {
            navigate("/auftrag");
        }
    };

  return (
    <div className="flex w-full justify-between md:pl-[49px] md:pr-[69px]  pl-[10px] pr-[10px] ">
      {/* <Button className="header_space_brand" href="/" type="link">
          <span className="button-content">
            <CgWebsite size={32} />
            <span className={"logo-title"}>VALEST</span>
          </span>
        </Button> */}

      {isSwitchOn ? (
        <img
            onClick={leaveAuftrag}
          src={require("../../assets/Untitled-1zczxczx.png")}
          alt=""
          className="w-198px md:h-[102px] h-[50px] mt-[20px] cursor-pointer"
        />
      ) : (
        <img
          src={require("../../assets/h-logo.png")}
          alt=""
          onClick={leaveAuftrag}
          className="w-198px md:h-[102px] h-[50px] mt-[20px] cursor-pointer"
        />
      )}




      <div
        className={` switchparent  max-w-[695px] w-[60%] lg:w-[80%] rounded-bl-[58px] rounded-br-[58px] flex justify-center  ${isSwitchOn ? "bg-black" : "bg-white"
          }`}
      >
        {user && (
          <>
            <div className="flex justify-center items-center">

                <Button
                    onClick={leaveAuftrag}
                  // href="/auftrag"
                  className={` border-none auth_button_login text-[10px] pl-[6px] pr-[6px] md:pl-[15px] md:pr-[15px]  md:text-[14px] bgnone text-black" type="link ${isSwitchOn ? "whiteText" : "textdark"
                    }`}
                >
                  Aufträge
                </Button>

              |
              <Link to="/kontakte" target={"_blank"}>
                <Button
                  // href="/kontakte"
                  className={`border-none bgnone auth_button_login text-[10px] pl-[8px] pr-[8px] md:pl-[15px] md:pr-[15px] md:text-[14px] text-black" type="link ${isSwitchOn ? "whiteText" : "textdark"
                    }`}
                >
                  Kontakte
                </Button>
              </Link>
              |
              <Link to="/textbausteine" target={"_blank"}>
                <Button
                  className={` border-none bgnone auth_button_login text-[10px] pl-[8px] pr-[8px] md:pl-[15px] md:pr-[15px] md:text-[14px] text-black" type="link ${isSwitchOn ? "whiteText" : "textdark"
                    }`}
                  // href="/profile"
                >
                  Textbausteine
                </Button>
              </Link>
            </div>
            <div className="ml-[20px] switchild">
              <Switch
                className={isSwitchOn ? "bg-[#a3b6a3]" : "bg-[#112011]"}
                defaultChecked={false}
                onChange={toggleSwitch}
              />
            </div>
          </>
        )}
      </div>
      <div className="auth_buttons flex items-center">
        {user ? (
          <>
            <div
              className="auth_button_signUp toolparent"
              type="primary"
              // onClick={handleLogout}
              onClick={toggleTooltip}
            >
              <img
                src={require("../../assets/avatar.png")}
                className="md:w-[58px] w-[45px] md:h-[58px] h-[45px] cursor-pointer object-contain"
              />
            {isTooltipVisible && (
                <div className="custom-tooltip toolchild">
                    {user.role.type === "administrator" && (
                        <Link to="/nutzerverwaltung" className="text-black myprof">Nutzerverwaltung</Link>
                    )}
                    <p className="mt-[10px] myprof">
                        <Link to="/profile" className="text-black myprof">Kontoeinstellungen</Link>
                    </p>
                    <p className="mt-[10px] myprof" onClick={handleLogout}>Abmelden</p>
                </div>
            )}
            </div>
          </>
        ) : (
          <>
            <Link className="auth_Link_login whiteText text-[16px]" to="/" >
              Login
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
