import React, { createContext, useContext, useState } from 'react';

const SwitchContext = createContext();

export const SwitchProvider = ({ children }) => {
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    const toggleSwitch = () => {
        setIsSwitchOn((prev) => !prev);
    };

    return (
        <SwitchContext.Provider value={{ isSwitchOn, toggleSwitch }}>
            {children}
        </SwitchContext.Provider>
    );
};

export const useSwitch = () => {
    const context = useContext(SwitchContext);
    if (!context) {
        throw new Error('useSwitch must be used within a SwitchProvider');
    }
    return context;
};