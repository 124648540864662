import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Row,
    Spin,
} from "antd";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import useScreenSize from "../hooks/useScreenSize";
import { getToken, setToken } from "../helpers";
import axios from "axios";
import {getAPIurl} from "../helpers/formHelpers";

const ResetPassword = () => {
    const { isDesktopView } = useScreenSize();
    const navigate = useNavigate();

    const { setUser } = useAuthContext();

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState("");

    const onFinish = async (values) => {
        setIsLoading(true);
        axios
            .post(`${getAPIurl()}/auth/forgot-password`, {
                email: values.email, // user's email
            })
            .then((response) => {
                console.log("Your user received an email");
                message.success(`You have received an email!`);

                navigate("/reset-password", { replace: true });
            })
            .catch((error) => {
                console.log("An error occurred:", error.response);
                setError(error?.message ?? "Something went wrong!");
            })
            .finally((ee) => {
                setIsLoading(false);
            });
    };

    return (
        <Fragment>
            <Row align="middle " style={{ height: "100vh" }}>
                <Col span={isDesktopView ? 12 : 24} className="  mb-[100px]">
                    <div className="w-full flex content-center items-center flex-col">
                        <img
                            src={require("../assets/log.png")}
                            className="h-[224px]  w-[70%] mt-[30px] sm:mt-0"
                        />
                        <div className="mt-[78px] w-[90%] text-center text-white text-2xl font-bold ">
                            hr digitaler Assistent zur Erstellung von professionellen
                            Verkehrswertgutachten nach BauGB und ImmoWertV.
                        </div>
                        <div className="w-[90%] text-center text-white text-lg font-normal leading-[54px]">
                            Schnelle & effiziente Gutachtenerstellung in einer App!
                        </div>
                    </div>
                </Col>
                <Col
                    span={isDesktopView ? 12 : 24}
                    style={{ background: "#ffe9ea" }}
                    className="signTop"
                >
                    <Card
                        title="Forgot Password"
                        className="anmeldescreen"
                        style={{ backgroundColor: "transparent", width: "80%" }}
                    >
                        {error ? (
                            <Alert
                                className="alert_error"
                                message={error}
                                type="error"
                                closable
                                afterClose={() => setError("")}
                            />
                        ) : null}
                        <Form
                            name="basic"
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item name="password" rules={[{ required: true }]}>
                                <Input.Password
                                    placeholder="Password"
                                    className="h-[50px] rounded"
                                />
                            </Form.Item>
                            <Form.Item name="password" rules={[{ required: true }]}>
                                <Input.Password
                                    placeholder="Password"
                                    className="h-[50px] rounded"
                                />
                            </Form.Item>

                            <Form.Item>
                                <div className="flex justify-center">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="w-44 h-[53px] bg-red-500 rounded shadow"
                                    >
                                        Change Password {isLoading && <Spin size="small" />}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ResetPassword;
