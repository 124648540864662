import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import useScreenSize from "../hooks/useScreenSize";
import { getToken, setToken } from "../helpers";
import { useSwitch } from "../pages/SwitchContext";
import {getAPIurl} from "../helpers/formHelpers";

const SignIn = () => {
  const { isDesktopView } = useScreenSize();
  const navigate = useNavigate();
  const { isSwitchOn, toggleSwitch } = useSwitch();

  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const value = {
        identifier: values.email,
        password: values.password,
      };
      const response = await fetch(`${getAPIurl()}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        // set the token
        setToken(data.jwt);

        // set the user
        setUser(data.user);

        message.success(`Willkommen zurück ${data.user.username}!`);

        navigate("/auftrag", { replace: true });
      }
    } catch (error) {
      console.error(error);
      setError(error?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {


    // if (getToken() !== null) {
    //   navigate("/auftrag")
    // }
  }, [])
  return (
    <Fragment >
      <Row align="middle md:pb-0 pb-[20px] " >
        <Col span={isDesktopView ? 12 : 24} className=" mb-[30px] md:mb-[100px]">
          <div className="w-full flex content-center items-center flex-col">
            <img src={require("../assets/log.png")} className="md:h-[224px] h-[80px]  w-[445px] mt-[30px] sm:mt-0" />
            <div className="md:mt-[78px] mt-[20px] w-[90%] text-center text-black md:text-2xl text-[18px] font-bold ">Ihr digitaler Assistent zur Erstellung von professionellen Verkehrswertgutachten nach BauGB und ImmoWertV.</div>
            <div className="w-[90%] text-center text-black text-lg font-normal leading-[20px] mt-[10px] md:mt-0 md:leading-[54px]">Schnelle & effiziente Gutachtenerstellung in einer App!</div>
          </div >
        </Col >
        <Col
          span={isDesktopView ? 12 : 24}
          style={{ background: "#ffe9ea" }}
          className="signTop w-[98%] md:w-auto"

        >
          <Card
            title="Anmeldung"
            className="anmeldescreen generalfamily anmeldung md:w-[80%]"
            style={{ backgroundColor: "transparent" }}
          >
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                // label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Benutzername/Email" className="md:h-[50px] h-[40px] w-[300px] md:w-full rounded" />
              </Form.Item>

              <Form.Item
                // label="Password"
                // className="h-[50px] bg-white rounded-[7.14px] shadow border border-white"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Passwort" className="md:h-[50px] h-[40px] w-[300px] md:w-full rounded" />
              </Form.Item>

              <Form.Item>
                <div className="flex justify-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={`w-44 h-[53px] text-[20px] border-none ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} whiteText rounded shadow`}
                  >
                    Anmeldung {isLoading && <Spin size="small" />}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row >
    </Fragment >
  );
};

export default SignIn;
