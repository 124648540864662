import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


import AllgemeineAngaben from "../components/Gutachten/AllgemeineAngaben";
import Lage from "../components/Gutachten/Lage";
import Grundstueck from "../components/Gutachten/Grundstueck";
import Gebaeude from "../components/Gutachten/Gebaeude";
import Anlagen from "../components/Gutachten/Anlagen";
import Wertermittlung from "../components/Gutachten/Wertermittlung";
import Vorschau from "../components/Gutachten/Vorschau";
import DokumenteLinks from "../components/Gutachten/DokumenteLinks";
import { useSwitch } from '../pages/SwitchContext';

import { Link } from "react-router-dom";
import {getAuftrag, getToken} from "../helpers";
import { useLocation } from "react-router-dom"
import {getAPIurl} from "../helpers/formHelpers";
const gutachtenTabs = ["allgemeine-angaben", "lage", "grundstueck", "gebaeude", "wertermittlung", "anlagen", "dokumente-links", "vorschau"];

/*const items = [ // const items: TabsProps['items'] = [
  {
    key: '2',
    label: (
      <span>
        <BarsOutlined />
        Allgemeine Angaben
      </span>
    ),
    children: <AllgemeineAngaben />,
  },
  {
    key: '3',
    label: (
      <span>
        <EnvironmentOutlined />
        Lage
      </span>
    ),
    children: <Lage />,
  },
  {
    key: '4',
    label: (
      <span>
        <NumberOutlined />
        Grundst&uuml;ck
      </span>
    ),
    children: <Grundstueck />,
  },
  {
    key: '5',
    label: (
      <span>
        <HomeOutlined />
        Geb&auml;ude & Außenanlagen
      </span>
    ),
    children: <Gebaeude />,
  },
  {
    key: '6',
    label: (
      <span>
        <CalculatorOutlined />
        Wertermittlung
      </span>
    ),
    children: <Wertermittlung />,
  },
  {
    key: '7',
    label: (
      <span>
        <CloudUploadOutlined />
        Anlagen
      </span>
    ),
    children: <Anlagen />,
  },
  {
    key: '8',
    label: (
        <span>
        <CloudUploadOutlined />
        Übersicht Dokumente & Links
      </span>
    ),
    children: <DokumenteLinks />,
  },
  {
    key: '9',
    label: (
      <span>
        <CheckCircleOutlined />
        Vorschau
      </span>
    ),
    children: <Vorschau />,
  },
];*/


const steps = [
  "Allgemeine Angaben",
  "Lage",
  "Grundstück",
  "Gebäude & Außenanlagen",
  "Wertermittlung",
  "Anlagen",
  "Übersicht \n Dokumente & Links",
  "Vorschau",
];

const components = [
  <AllgemeineAngaben />,
  <Lage />,
  <Grundstueck />,
  <Gebaeude />,
  <Wertermittlung />,
  <Anlagen />,
  <DokumenteLinks />,
  <Vorschau />,
];


const Gutachten: React.FC = () => {
  const { auftragID, gutachtenID, tab, objektID } = useParams(); // Get the activeKey from the URL
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const { isSwitchOn, toggleSwitch } = useSwitch();
  const [dataContent, setDataContent] = useState(null);


  let location = useLocation()

  const fetchGutachtenInfos = async () => {
    try {
      const response = await fetch(`${getAPIurl()}/gutachtens/${gutachtenID}?populate[0]=AllgAng.AllgAng_Detail`, { // &filters[role][$eq]=${userRole}
        headers: {
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
      });
      //console.log(`${API}/vollgutachtens/${gutachtenID}?populate=deep`);
      const dataContainer = await response.json();
      setDataContent(dataContainer.data.attributes.AllgAng.AllgAng_Detail);
    } catch (error) {

    }
  }

  useEffect(() => {
    // Update the URL based on the default component (Only if undefined)

    if(tab==undefined){
      const defaultUrl = `/gutachten/${auftragID}/${gutachtenID}/${gutachtenTabs[activeStep]}`;
      navigate(defaultUrl);
      setActiveStep(0);
    } else {
      navigate(location.pathname+location.hash);
      setActiveStep(gutachtenTabs.indexOf(tab));
    }


  }, [auftragID, gutachtenID]);

  useEffect(() => {
    fetchGutachtenInfos().then();
  }, []);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
    const newUrl = `/gutachten/${auftragID}/${gutachtenID}/${gutachtenTabs[step]}`;
    navigate(newUrl);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* <Tabs
        type="card" m
        items={items}
        onChange={onChange}
        defaultActiveKey={activeKey}
      /> */}
      <>
        {dataContent && (
            windowWidth > 768 ? (
            <div
                className={`p-4 xl:ml-0  ml-[15px] mr-[15px] ${isSwitchOn ? 'bg-black' : 'bg-white'} ${isSwitchOn ? 'borderRadiuClass1' : ''} rounded-tr-20 mb-4 md:mb-[20px] borderRadiuClass max-w-[100%] md:pl-2`}>
              <div className={`flex flex-row`}>
                <div
                    className={`basis-1/2 generalText ml-[6px] text-13 mb-4 md:text-16 ${isSwitchOn ? 'text-white' : 'text-black'}`}>
                  <Link to="/">Startseite</Link>/<Link
                    to={`/auftrag/${location.pathname.split("/")[2]}`}>Auftrag</Link>/ <span
                    className="text-19 font-bold"> <Link
                    to={`/gutachten/${location.pathname.split("/")[2]}/${location.pathname.split("/")[3]}`}>Gutachten</Link></span>
                </div>
                <div
                    className={`basis-1/2 text-right generalText ml-[6px] mr-[6px] text-13 mb-4 md:text-16 ${isSwitchOn ? 'text-white' : 'text-black'}`}>
                  {dataContent.Strasse} {dataContent.NrZusatz}
                  {dataContent.Ort && (dataContent.Strasse || dataContent.NrZusatz) && ", "}
                  {dataContent.Ort}
                </div>
              </div>
              <Stepper activeStep={activeStep} alternativeLabel className={`basis-full`}>
                {steps.map((label, index) => (
                    <Step key={label} onClick={() => handleStepChange(index)}>
                      <StepLabel className={"cursor-pointer"}>{label}</StepLabel>
                    </Step>
                ))}
              </Stepper>
            </div>
        ) : (
            <div className="flex flex-wrap">
              {steps.map((label, index) => (
                  <div
                      key={label}
                      onClick={() => handleStepChange(index)}
                      className={`flex items-center justify-center cursor-pointer p-2 m-2 text-white ${activeStep === index ? 'bg-red-500' : 'bg-gray-500'
                      } rounded`}
                  >
                  <div>{label}</div>
              </div>
            ))}
          </div>
        )
        )}
      </>
      {components[activeStep]}
    </>

  );
};


const Connector: React.FC<{ activeStep: number }> = ({ activeStep }) => {
  return (
    <div
      style={{
        flex: 1,
        borderTop: `2px solid ${activeStep === steps.length - 1 ? "#bdbdbd" : "#FF2E3B"}`,
      }}
    />
  );
};

export default Gutachten;