import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Profile from "./components/Profile/Profile";
import { getToken } from "./helpers";
import SignIn from "./pages/SignIn";

import Gutachten from "./pages/Gutachten";
import KontakteUebersicht from "./pages/KontakteUebersicht";
import KontaktDetails from "./pages/KontaktDetails";
import AuftragUebersicht from "./pages/AuftragUebersicht";
import AuftragDetails from "./pages/AuftragDetails";
import Startseite from "./pages/_Startseite";
import ResetPassword from "./pages/ResetPassword";
import Textbausteine from "./pages/Textbausteine";
import TextbausteineEdit from "./pages/TextbausteineEdit";
import UserAdministration from "./pages/UserAdministration";
import { Redirect } from "react-router-dom";
import UserEdit from "./pages/UserEdit";
const AdminRoutes = () => {

  return getToken() !== null ? <Outlet /> : <Navigate to="/" />;
};
const LoggedIn = () => {
  return getToken() === null ? <Outlet /> : <Navigate to="/auftrag" />;
};
const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<AdminRoutes />}>
        {/* <Route path="/home" element={<Startseite />} /> */}
        <Route path="/auftrag" element={<AuftragUebersicht />} />
        <Route path="/textbausteine" element={<Textbausteine />} />
        <Route path="/textbausteine/:id" element={<TextbausteineEdit />} />

        <Route path="/auftrag/:auftragID" element={<AuftragDetails />} />
        <Route path="/gutachten" element={<Navigate to="/" />} />
        <Route
          path="/gutachten/:auftragID/:gutachtenID"
          element={<Gutachten />}
        />
        <Route
          path="/gutachten/:auftragID/:gutachtenID/:tab"
          element={<Gutachten />}
        />
        <Route
          path="/gutachten/:auftragID/:gutachtenID/:tab/:objektID"
          element={<Gutachten />}
        />
          <Route path="/nutzerverwaltung" element={<UserAdministration />} />
        <Route path="/nutzerbearbeiten" element={<UserEdit />} />
        <Route path="/nutzerbearbeiten/:userId" element={<UserEdit />} />
        <Route path="/kontakte" element={<KontakteUebersicht />} />
        <Route path="/kontakte/:kontaktID" element={<KontaktDetails />} />
        <Route path="/kontakte/:kontaktID/:auftragID" element={<KontaktDetails />} />
        {/* <Route path="/dokumente/" element={<_Dokumente />} /> */}
        <Route
          path="/profile"
          element={getToken() ? <Profile /> : <Navigate to="/signin" />}
        />
      </Route>
      <Route element={<LoggedIn />}>
        <Route path="/" element={<SignIn />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
