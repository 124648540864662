import React from "react";
import { Col, Layout, Row } from "antd";
import AppHeader from "./components/Appheader/Appheader";
import AppRoutes from "./Routes";
import { useAuthContext } from "./context/AuthContext";
import { useLocation } from "react-router-dom";
import { useSwitch } from "./pages/SwitchContext";
import {BackToTopButton} from "./helpers/formHelpers";
import CookieConsent from "react-cookie-consent";

const { Header, Content } = Layout;


const App = () => {
  const location = useLocation();
  const { isSwitchOn, toggleSwitch } = useSwitch();
  return (
    <Layout className={` ${isSwitchOn ? "backgrou2" : "backgrou1"}`}>
      <Row gutter={[0, 24]}>
        {location.pathname !== "/" &&
          location.pathname !== "/forgot" &&
          location.pathname !== "/reset-password" ? (
          <Col span={24}>
            <div className="bg-transparent h-auto ">
              <AppHeader />
            </div>
          </Col>
        ) : (
          <></>
        )}
        <Col
          span={location.pathname !== "/" ? 24 : 24}
          offset={location.pathname !== "/" ? 0 : 0}
        >
          <div className="appContainer">
            <Content>
              <AppRoutes />
            </Content>
          </div>
          <BackToTopButton />
        </Col>
      </Row>
      <CookieConsent
          overlay
          location="top"
          buttonText="Akzeptieren"
          cookieName="VALESTCookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
      >
        Diese Web-App nutzt Cookies zur Authentifizierung.{" "}
      </CookieConsent>
    </Layout>
  );
};

export default App;
