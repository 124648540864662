import {
    Button,
    Card,
    Col,
    message,
    Row,
    Anchor,
    Form,
    InputNumber,
    Modal, Select, Input, Checkbox, Upload
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useEffect, useRef, useState} from "react";
import { getToken } from "../../helpers";
import {
    changeMultiSelectValues, getAPIurl,
    getInitialValuesFormular, isTextFieldActive, LoadingScreen, MyInputNumber, openS3File, optimizedHandleScroll,
    postBewertungsobjekt, SaveIcon, setInitialUploadForms,
    updateDataContent, uploadFile, useModals
} from "../../helpers/formHelpers";
import { useNavigate, useParams } from "react-router-dom";
import {DeleteOutlined, ExportOutlined, FileTextOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import useScreenSize1 from "../../hooks/useScreenSize1";
import { useSwitch } from '../../pages/SwitchContext';
import { HashLink as Linki } from "react-router-hash-link";
import { RxCross2 } from "react-icons/rx";
import { RiDeleteBin5Fill } from "react-icons/ri";
const { Link } = Anchor;

let dataContainer;
let dataContent;
let navigate;
let enumerationValuesContent;
let updateDataContentValue;
let updateDataContentContainer;
//let initialValues;
let currentFileChanged;
let currentFileChangedID;
let currentFileChangedComponent;
const putBody = {};
const { Option } = Select;

const Wertermittlung = () => {
    const { gutachtenID, tab, objektID } = useParams(); // Get the activeKey from the URL
    const [Vergleichswert, setVergleichswert] = useState(false);
    const [Ertragswert, setErtragswert] = useState(false);
    const [Sachwert, setSachwert] = useState(false);
    const [optionVergleichswert, setOptionVergleichswert] = useState(true);
    const [optionErtragswert, setOptionErtragswert] = useState(true);
    const [optionSachwert, setOptionSachwert] = useState(true);
    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize1();
    const { isSwitchOn, toggleSwitch } = useSwitch();

    useEffect(() => {
        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }
    }, [isDesktopView]);
    const stateUpdaters = {
        setVergleichswert,
        setErtragswert,
        setSachwert,
        setOptionVergleichswert,
        setOptionErtragswert,
        setOptionSachwert
    };
    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };

    if (objektID != undefined) {
        return (
            <>
                {/* <Row>
                    <Col md={4} lg={4} sm={24} xs={24}>

                    </Col>
                    <Col md={20} lg={20} sm={24} xs={24} className="FormContainer">

                    </Col>
                </Row> */}
                <div className="w-full">
                    <div
                        className={`${!isDesktopView ? "visible  cursor-pointer" : "hidden"
                            } text-white pl-[15px]`}
                        onClick={() => {
                            setVis(!vis);
                        }}
                    >
                        <button className="menuBtn">Menu</button>
                    </div>
                    <div
                        className={`flex flex-row gap-[30px] relative mr-[15px]  mb-[40px] ${!isDesktopView ? "ml-[15px]" : ""
                            }`}
                    >
                        {vis ? (
                            <div className="w-[299px] absolute xl:relative z-10 h-full xl:h-auto  ">
                                <MenuContainerDetails
                                    stateValues={{ Vergleichswert, Ertragswert, Sachwert, optionVergleichswert, optionErtragswert, optionSachwert }}
                                    stateUpdaters={stateUpdaters}
                                    vis={vis}
                                    onVisibilityChange={handleVisibilityChange}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="w-full ${isSwitchOn ? 'bg-black' : 'bg-white'} rounded-[30px]">
                            <FormContainerDetails
                                stateValues={{ Vergleichswert, Ertragswert, Sachwert, optionVergleichswert, optionErtragswert, optionSachwert }}
                                stateUpdaters={stateUpdaters} />
                        </div>
                    </div>
                </div>
            </>

        );
    } else {
        return (
            <Row>
                <Col md={1} lg={1} sm={1} xs={1}>
                </Col>
                <Col md={22} lg={22} sm={22} xs={22} style={{ paddingLeft: "15px", paddingRight: "15px" }} className="FormContainer">
                    <FormContainerUebersicht
                        Vergleichswert={Vergleichswert}
                        Ertragswert={Ertragswert}
                        Sachwert={Sachwert} />
                </Col>
            </Row>
        );
    }
};



const FormContainerUebersicht = () => {
    const [loading, setIsLoading] = useState(false);
    const { auftragID, gutachtenID, tab, objektID } = useParams();
    const { isSwitchOn, toggleSwitch } = useSwitch(); // Get the activeKey from the URL

    navigate = useNavigate();

    const fetchWertermittlungUebersicht = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(`${getAPIurl()}/gutachtens/${gutachtenID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            dataContainer = await response.json();
            dataContent = dataContainer.data.attributes;

        } catch (error) {
            console.log(error);
            message.error("Error while fetching gutachten!" + error);
        } finally {
            //When everything is loaded jump to anchor (if selected)
            let currentUrl = window.location.href.split("#");
            if(currentUrl[1]){ // Anchor(=Hashtag) is in URL
                document.querySelector('a[href="#'+currentUrl[1]+'"]').click();
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchWertermittlungUebersicht().then();

    }, []);

    // Sende einen POST-Request an deine Strapi-API, um den neues Bewertungsobjekt zu erstellen
    const addBewertungsobjekt = async () => {
        try {
            if (Object.keys(dataContent.bewertungsobjekts.data).length > 0) {
                const responseBOData = await postBewertungsobjekt(gutachtenID);
                const newUrl = `/gutachten/${auftragID}/${gutachtenID}/Wertermittlung/${responseBOData.id}` // => Navigate to new object
                navigate(newUrl);
            } else { // In Case, there is no Bewertungsobjekt existing, create two and stay on overview
                await postBewertungsobjekt(gutachtenID);
                await postBewertungsobjekt(gutachtenID);
                window.location.reload();
            }
        } catch (error) {
            // Hier können Sie Fehler behandeln, wenn die Anfrage fehlschlägt
            console.error("Fehler beim Senden der POST-Anfrage:", error);
        }
    }

    const deleteBewertungsobjekt = (id) => {
        Modal.confirm({
            title: 'Bewertungsobjekt löschen',
            content: 'Möchten Sie dieses Bewertungsobjekt wirklich löschen?',
            onOk() {
                deleteBewertungsobjektConfirmed(id);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    };
    async function deleteBewertungsobjektConfirmed(deleteID) {
        try {
            const response = await fetch(`${getAPIurl()}/bewertungsobjekts/${deleteID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            if (response.ok) {
                console.log(`Eintrag mit ID ${deleteID} wurde erfolgreich gelöscht.`);
                window.location.reload();
            } else {
                console.error(`Fehler beim Löschen des Eintrags: ${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            console.error('Fehler beim Löschen des Eintrags:', error);
        }
    }

    return (
        <div className={` p-[20px] rounded-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            {loading && <LoadingScreen />}

            <h2 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Wertermittlung</h2>
            <div className="cardsBewertungsobjekt md:pr-[10%]  md:pl-[10%]">
                {/* Hier ist Ihre Hauptkachel */}
                <Linki className={` ${isSwitchOn ? 'cardsGutachten1' : 'cardsGutachten'}`} to={`/gutachten/${auftragID}/${gutachtenID}/wertermittlung/allgemein`}><Card className="w-full gutachtCard max-w-[460px]">
                    <h2 className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`}>Gutachtenübergreifende Wertermittlung</h2>
                </Card></Linki>

                <hr />
                <Row gutter={16} className="mt-[20px]">
                    {/* Hier sind die kleineren Kacheln */}
                    {dataContent && dataContent.bewertungsobjekts && dataContent?.bewertungsobjekts?.data?.map((entry, index) => (
                        <Col className={` ${isSwitchOn ? 'cardsGutachten1' : 'cardsGutachten'} flex flex-wrap`} key={entry.id}> {/* Achten Sie auf den 'key'-Prop für die Iteration */}
                            <Card className="w-[320px]">
                                <div style={{ float: 'right', cursor: 'pointer' }} onClick={() => deleteBewertungsobjekt(entry.id)}>
                                    <RiDeleteBin5Fill className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`} />
                                </div>
                                <Linki to={`/gutachten/${auftragID}/${gutachtenID}/wertermittlung/${entry.id}`}>
                                    <h2 className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`}>Bewertungsobjekt {index + 1}<br /><b>{entry.attributes.Name_BO}</b></h2>
                                </Linki>
                            </Card>
                        </Col>
                    ))}


                    <Col span={8}>
                        <Card className="w-[320px]">
                        <Linki to={"#"} className={`${isSwitchOn ? 'cardsGutachten1' : 'cardsGutachten'}`} onClick={() => addBewertungsobjekt()}>
                            <h2 className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`}><PlusOutlined />
                                Bewertungsobjekt anlegen</h2>
                        </Linki></Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};


const MenuContainerDetails = ({ stateValues, stateUpdaters, vis, onVisibilityChange }) => {
    window.addEventListener('scroll', optimizedHandleScroll);
    const { auftragID, gutachtenID, tab, objektID } = useParams();
    const { isSwitchOn, toggleSwitch } = useSwitch(); // Get the activeKey from the URL
    // Destructure individual state values
    const { Vergleichswert, Ertragswert, Sachwert } = stateValues;
    const [isInLower20, setIsInLower20] = useState(false);
    const handleClick = () => {
        const newVisibility = !vis;
        onVisibilityChange(newVisibility);
    };
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Check if the device height is less than 600px
            const isDeviceHeightBelow600 = window.innerHeight < 650;

            // Calculate the scroll position percentage
            const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

            // Set the state based on the scroll position and device height
            setIsInLower20(isDeviceHeightBelow600 && scrollPercentage >= 80);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={`sidebar rounded-r-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <div className={`ml-[197px]`}><RxCross2 onClick={handleClick} className={`lg:hidden ${isSwitchOn ? 'text-white' : 'text-blaxk'} text-[30px] mb-[20px] `} /></div>
            <Linki to={`/gutachten/${auftragID}/${gutachtenID}/wertermittlung/`}>
                <Button className={` ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} border-none ml-[16px] rounded-full buttonHover" type="primary`}>Zurück zur Übersicht</Button>
            </Linki>
            <Anchor affix={false} offsetTop={80} className={`your-component ${isInLower20 ? 'lower-20' : ''}`}>
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#verfahren" title="Verfahren" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#bodenwert" title="Bodenwert" />
                {Vergleichswert && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#vergleichswert" title="Vergleichswert" />)}
                {Ertragswert && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#ertragswert" title="Ertragswert" />)}
                {Sachwert && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#sachwert" title="Sachwert" />)}
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#bog" title="Besondere objektspezifische Grundstücksmerkmale" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#beurteilung" title="Beurteilung" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#verkehrswert" title="Verkehrswert" />
            </Anchor>
        </div>
    );
};

const FormContainerDetails = ({ stateValues, stateUpdaters }) => {
    const [saveIcon, setSaveIcon] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [saveIcon1, setSaveIcon1] = useState(false);
    const { auftragID, gutachtenID, tab, objektID } = useParams(); // Get the activeKey from the URL
    const [formFormular1] = Form.useForm();
    const inputRefs = useRef({});
    const [initialValues, setInitialValuesState] = useState([]);
    const handleRef = (name, element) => {
        if (element) {
            inputRefs.current[name] = element;
        }
    };
    const [uploadForms, setUploadForms] = useState([]);
    const updatedUploadForms = [...uploadForms];
    const [selectedOption, setSelectedOption] = useState(null);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [dataContentRefresh, setDataContent] = useState(false);
    const [cards, setCards] = useState([]);
    const { modals, handleOpenTextbausteine, handleCloseTextbausteine, renderModals, selectedTextBausteine } = useModals(); //Drawer for Textbausteine
    // Destructure individual state values
    const { Vergleichswert, Ertragswert, Sachwert, optionVergleichswert, optionErtragswert, optionSachwert } = stateValues;
    // Destructure individual state updater functions
    const { setVergleichswert, setErtragswert, setSachwert, setOptionVergleichswert, setOptionErtragswert, setOptionSachwert } = stateUpdaters;
    const { isSwitchOn, toggleSwitch } = useSwitch();

    const handleSelectChange = (value) => {

        // 1.Step: Show  Option for Stützverfahren, that was hidden because of previous select
        if(selectedOption=="Vergleichswert") {
            setOptionVergleichswert(true);
        }
        if (selectedOption == "Ertragswert") {
            setOptionErtragswert(true);
        }
        if (selectedOption == "Sachwert") {
            setOptionSachwert(true);
        }
        // 2.Step: Hide Option for Stützverfahren, if its choosen for Hauptverfahren
        // 3.Step: Activate selectedOption
        if(value==undefined) {
            showVerfahrenswahl("Vergleichswert",false,true);
            showVerfahrenswahl("Ertragswert",false,true);
            showVerfahrenswahl("Sachwert",false,true);
        }
        if(value=="Vergleichswert") {
            setOptionVergleichswert(false);
            showVerfahrenswahl("Vergleichswert",true,true);
            showVerfahrenswahl("Ertragswert",false,true);
            showVerfahrenswahl("Sachwert",false,true);
        }
        if (value == "Ertragswert") {
            setOptionErtragswert(false);
            showVerfahrenswahl("Ertragswert",true,true);
            showVerfahrenswahl("Vergleichswert",false,true);
            showVerfahrenswahl("Sachwert",false,true);

        }
        if (value == "Sachwert") {
            setOptionSachwert(false);
            showVerfahrenswahl("Sachwert",true,true);
            showVerfahrenswahl("Ertragswert",false,true);
            showVerfahrenswahl("Vergleichswert",false,true);
        }
        //Save new select
        setSelectedOption(value);
    };

    // Update Collection Gutachten
    const updateGutachten = (putBody, forceReload = false, forcePageReload = false) => {

        if (forcePageReload) {
            setIsLoading(true);
        } else {
            setSaveIcon(true);
            setSaveIcon1(true)
        }
        fetch(`${getAPIurl()}/gutachtens/${gutachtenID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(putBody),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update data.');
                }

                return response.json();
            })
            .then((responseData) => {
                // New component (undefined item.id) => reload;
                if (forceReload) {
                    if (forcePageReload == true) {
                        window.location.reload();
                    } else {
                        setUpdateTrigger((prev) => !prev);
                        setIsLoading(false);
                    }
                }

                // Setze das Speicher-Icon nach X Millisekunden zurück
                setTimeout(() => { setSaveIcon(false); }, 300);
                setTimeout(() => {
                    setSaveIcon1(false);
                }, 3000);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    // Update Collection Bewertungsobjekt
    const updateBewertungsobjekt = (putBody, forceReload = false, forcePageReload = false) => {
        setSaveIcon(true);
        setSaveIcon1(true)
        fetch(`${getAPIurl()}/bewertungsobjekts/${objektID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(putBody),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update data.');
                }
                //console.log('putBody:', putBody);
                return response.json();
            })
            .then((responseData) => {
                // Upload => reload;
                if (forceReload) {
                    if (forcePageReload == true) {
                        window.location.reload();
                    } else {
                        setUpdateTrigger((prev) => !prev);
                        setIsLoading(false);
                    }
                }

                // Setze das Speicher-Icon nach X Millisekunden zurück
                setTimeout(() => { setSaveIcon(false); }, 300);
                setTimeout(() => {
                    setSaveIcon1(false);
                }, 3000);
            })
            .catch((error) => {
                console.log('putBody:', putBody);
                console.error('Error:', error);
            });
    }
const showVerfahrenswahl = (value, checked, selectOption=false) => {
        const stuetzVerfahrenArr = dataContent.Wertermittlung.Stuetzverfahren;

        if(selectOption && !checked && stuetzVerfahrenArr != null){ // Keep active "Stützverfahren" active
            if (value == "Vergleichswert" && stuetzVerfahrenArr.includes("Vergleichswert")) {
                checked = true;
            }
            if (value == "Ertragswert" && stuetzVerfahrenArr.includes("Ertragswert")) {
                checked = true;
            }
            if (value == "Sachwert" && stuetzVerfahrenArr.includes("Sachwert")) {
                checked = true;
            }
        }

       if(checked == true){
           if (value == "Vergleichswert") {
               setVergleichswert(true);
           }
           if (value == "Ertragswert") {
               setErtragswert(true);
           }
           if (value == "Sachwert") {
               setSachwert(true);
           }
       } else {
           if (value == "Vergleichswert") {
               setVergleichswert(false);
           }
           if (value == "Ertragswert") {
               setErtragswert(false);
           }
           if (value == "Sachwert") {
               setSachwert(false);
           }
       }



    }

    const customUploadFile = async (file) => {

        try {
            if (file) {
                const responseUF = await uploadFile(file, false, auftragID);
                // Check if the response is an array
                updateDataContentValue = {}

                if (Array.isArray(responseUF)) {
                    // Use forEach to iterate through the array (normally 1, because multiple files are not allowed)
                    responseUF.forEach((item, index) => {
                        updateDataContentValue["url"] = item.hash + item.ext;
                        updateDataContentValue["id"] = item.id;
                        updateDataContentValue["name"] = item.name;
                    });
                }
                if (Object.keys(updateDataContentValue).length === 0) {
                    updateDataContentValue = "-";
                }
                // Save changes in database
                updateDataContentContainer = dataContent;
                updateDataContent(currentFileChanged, updateDataContentValue, updateDataContentContainer, "updateByIndex");
                putBody.data = dataContent;
                if (objektID === "allgemein") {
                    updateGutachten(putBody, true, true);
                } else {
                    updateBewertungsobjekt(putBody, true, true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleRemoveAttachment = (dataContentEntry, file) => {
        Modal.confirm({
            title: 'Datei löschen',
            content: 'Möchten Sie die Datei wirklich löschen?',
            onOk() {
                handleRemoveAttachmentConfirmed(dataContentEntry, file);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    };

    async function handleRemoveAttachmentConfirmed(dataContentEntry, file) {
        try {
            const response = await fetch(`${getAPIurl()}/upload/files/${file}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            if (response.ok) {
                const responseData = await response.json();

            } else {
                console.log(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            return error;
        }
        updateDataContentContainer = dataContent;
        updateDataContent(dataContentEntry, "-", updateDataContentContainer, "updateByIndex");
        putBody.data = dataContent;
        if (objektID === "allgemein") {
            updateGutachten(putBody, true, true);
        } else {
            updateBewertungsobjekt(putBody, true, true);
        }
    }

    const FileChanged = (name, id, component) => {
        currentFileChanged = name;
        currentFileChangedID = id; //Für später (löschen)
        currentFileChangedComponent = component; // Keine Ahnungs obs gebraucht wird
    };

    const fetchWertermittlungDetails = async () => {
        setIsLoading(true);


        try {

            const enumerationValues = await fetch(`${getAPIurl()}/gutachten/enumvalues`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            enumerationValuesContent = await enumerationValues.json();

            if (objektID === "allgemein") { // Gutachtenübergreifend => Collection Gutachten
                const response = await fetch(`${getAPIurl()}/gutachtens/${gutachtenID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                    headers: {
                        // set the auth token to the user's jwt
                        Authorization: `Bearer ${getToken()}`,
                    },
                });
                dataContainer = await response.json();
                dataContent = dataContainer.data.attributes;
                //console.log(getInitialValuesFormular(dataContent));
                setInitialValuesState(getInitialValuesFormular(dataContent));
                //console.log(initialValues);
            } else { // Einzelnes Bewertungsobjekt => Collection Bewertungsobjekt
                const response = await fetch(`${getAPIurl()}/bewertungsobjekts/${objektID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                    headers: {
                        // set the auth token to the user's jwt
                        Authorization: `Bearer ${getToken()}`,
                    },
                });
                dataContainer = await response.json();
                dataContent = dataContainer.data.attributes;
                setInitialValuesState(getInitialValuesFormular(dataContent));
            }

            setUploadForms(setInitialUploadForms(updatedUploadForms, dataContent.Wertermittlung.Berechnungsexcel, "Berechnungsexcel", true));
        } catch (error) {
            console.log(error);
            message.error("Error while fetching gutachten!" + error);
        } finally {
            setDataContent(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(dataContent) {
            handleSelectChange(initialValues.Wertermittlung__Hauptverfahren);
        }
    }, [initialValues]); // This dependency array ensures the effect runs whenever initialValues changes


    useEffect(() => {
        fetchWertermittlungDetails().then();
        const interval = setInterval(() => {
            if(!isTextFieldActive()) { //Not if currently in a textfield
                fetchWertermittlungDetails().then();
            }
        }, 30000); // Every 30 Seconds (in case multiple persons are working OR multiple tabs)
        return () => clearInterval(interval);

    }, []);
    const triggerFocus = (name) => {
        if (inputRefs.current[name]) {
            inputRefs.current[name].focus();
        }
    };
    useEffect(() => {
        if (selectedTextBausteine) {
            formFormular1.setFieldsValue({ [modals[modals.length - 1].field]: selectedTextBausteine });
            triggerFocus(modals[modals.length - 1].field);
        }
    }, [selectedTextBausteine]); // Specify the dependencies to watch for changes
    useEffect(() => formFormular1.resetFields(), [initialValues]);
    return (
        <div className={`${isSwitchOn ? 'bg-black' : 'bg-white'} sm:p-[20px] p-[10px] rounded-[30px]`}>
            {saveIcon1 && <SaveIcon saveIcon={saveIcon} />}
            {loading && <LoadingScreen />}
            {renderModals()}
            {initialValues && dataContentRefresh && dataContent && enumerationValuesContent && ( // Bedingung überprüft, ob dataContent & enumerationValuesContent bereits vorhanden ist
                <Form
                    form={formFormular1}
                    onBlur={(e) => {
                        // Hier wird das onBlur-Ereignis für das gesamte Formular abgefangen
                        if (e.target.type != "button") { //Not at addCard or textbaustein
                            const updateByIDArray = ["Wertermittlung__Ausstattungsmerkmal"];
                            let targetID;
                            updateDataContentContainer = dataContent;
                            if (e.target.type === "search") {
                                updateDataContentValue = selectedOption;
                                //setSelectedOption(null); //Reset (for the case the next select is choosen without value)
                                targetID = e.target.id;
                            } else if (e.target.type === "checkbox") {
                                const curValues = getInitialValuesFormular(dataContent);
                                updateDataContentValue = changeMultiSelectValues(curValues.Wertermittlung__Stuetzverfahren, e.target.value, e.target.checked);
                                targetID = e.target.parentElement.parentElement.parentElement.id;
                            } else {
                                updateDataContentValue = e.target.value;
                                targetID = e.target.id;
                            }
                            if (updateByIDArray.some(value => e.target.id.includes(value))) {
                                updateDataContent(targetID, updateDataContentValue, updateDataContentContainer, "updateByID");
                            } else {
                                updateDataContent(targetID, updateDataContentValue, updateDataContentContainer, "updateByIndex");
                            }
                            putBody.data = dataContent;
                            if (objektID === "allgemein") {
                                updateGutachten(putBody);
                            } else {
                                updateBewertungsobjekt(putBody);
                            }
                            // New component (undefined item.id) => reload;
                            if (e.target.id.includes("undefined")) {
                                window.location.reload();
                            }
                        }
                    }}
                    initialValues={initialValues}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    layout="horizontal"
                    // style={{ maxWidth: 800 }}
                >
                    {objektID !== "allgemein" ? (
                        <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Name Bewertungsobjekt" name='Name_BO'>
                                <Input className="inputsAll"/>
                            </Form.Item>
                            <hr/>
                        </div>
                    ) : (
                        <div
                            className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>
                            Gutachtenübergreifende Wertermittlung
                            <hr/>
                        </div>
                    )}
                    <h2 className={`font-bold text-16px mb-[12px] mt-[12px] md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Wertermittlung</h2>

                    <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                        <Form.Item label="Berechnungsexcel" name={`Wertermittlung__Berechnungsexcel`}
                                   className={`max-content2 ${isSwitchOn ? 'maxColor' : ''} `}>
                            {uploadForms["Berechnungsexcel"] && uploadForms["Berechnungsexcel"] && uploadForms["Berechnungsexcel"].visible ? (
                                    <>
                                    <div>
                                        <Linki to={"https://valest.de/downloads/VALEST_Wertermittlung.xlsm"} target="_blank">
                                            <Button className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} border-none mb-10  rounded-full buttonHover1`} type="primary">Download Wertermittlungs-Excel</Button>
                                        </Linki>
                                <Upload.Dragger customRequest={customUploadFile}
                                                onChange={(info) => FileChanged(`Wertermittlung__Berechnungsexcel`, 0, "Berechnungsexcel")}
                                                accept=".xls,.xlsx,.xlsm">
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined className="text-red"/>
                                    </p>
                                    <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                        hochzuladen</p>
                                    <p className="ant-upload-hint">Unterstützte Dateiformate: .xls(x/m)</p>

                                </Upload.Dragger>
                                    </div>
                                    </>
                            ) : uploadForms["Berechnungsexcel"] !== undefined ? (
                                <>
                                    <div>
                                        <Button icon={<ExportOutlined/>}
                                                onClick={() => openS3File(uploadForms["Berechnungsexcel"].file.url)}>{uploadForms["Berechnungsexcel"].file.name}</Button>
                                        <Button icon={<DeleteOutlined/>}
                                                onClick={() => handleRemoveAttachment(`Wertermittlung__Berechnungsexcel`, uploadForms["Berechnungsexcel"].file.id)}>
                                            Löschen
                                        </Button>
                                    </div>
                                </>
                            ) : null}
                        </Form.Item>
                    </div>
                    <div>
                        <div id="verfahren" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Verfahren</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item
                                    label="Hauptverfahren" name="Wertermittlung__Hauptverfahren">
                                    <Select onChange={handleSelectChange}>
                                        {enumerationValuesContent.com_Hauptverfahren.map((option, index) => (
                                            <Option key={index} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item name="Wertermittlung__Stuetzverfahren" label="Stützverfahren">
                                    <Checkbox.Group>
                                        {optionVergleichswert && (
                                            <Checkbox value="Vergleichswert" checked={Vergleichswert}
                                                      onClick={(e) => showVerfahrenswahl(e.target.value, e.target.checked)}><span
                                                className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Vergleichswert</span>
                                            </Checkbox>
                                        )}{optionVergleichswert && (<br/>)}
                                        {optionErtragswert && (
                                            <Checkbox value="Ertragswert" checked={Ertragswert}
                                                      onClick={(e) => showVerfahrenswahl(e.target.value, e.target.checked)}><span
                                                className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Ertragswert</span>
                                            </Checkbox>
                                        )}{optionErtragswert && (<br/>)}
                                        {optionSachwert && (
                                            <Checkbox value="Sachwert" checked={Sachwert}
                                                      onClick={(e) => showVerfahrenswahl(e.target.value, e.target.checked)}><span
                                                className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Sachwert</span>
                                            </Checkbox>
                                        )}
                                    </Checkbox.Group>
                                </Form.Item>
                            </div>
                        </div>
                        <div id="bodenwert" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Bodenwert</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="€ pro m2" name='Wertermittlung__Bodenwert_m2'>
                                    <MyInputNumber className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Gesamt (in €)" name='Wertermittlung__Bodenwert_ges'>
                                    <MyInputNumber className="inputsAll"/>
                                </Form.Item>
                            </div>

                            <div
                                className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Merkmal" name='Wertermittlung__BW_Merkmal'>
                                    <TextArea className="textInput" autoSize={true}
                                              ref={(element) => handleRef('Wertermittlung__BW_Merkmal', element)}/>
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__BW_Merkmal', 'Merkmal', 'Bodenwert Merkmale')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                            </div>

                            <div
                                className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Erläuterungen" name='Wertermittlung__BW_Erlaeterungen'>
                                    <TextArea className="textInput" autoSize={true}
                                              ref={(element) => handleRef('Wertermittlung__BW_Erlaeterungen', element)}/>
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__BW_Erlaeterungen', 'Erläuterungen', 'Erläuterungen Bodenwert')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                            </div>

                            <div
                                className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Bodenrichtwert" name='Wertermittlung__Bodenwert'>
                                    <TextArea className="textInput" autoSize={true}
                                              ref={(element) => handleRef('Wertermittlung__Bodenwert', element)}/>
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__Bodenwert', 'Bodenrichtwert', 'Bodenwert')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                            </div>

                        </div>
                        {(Vergleichswert) && (
                            <div id="vergleichswert" className="scrollable-section mt-[20px]">
                                <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Vergleichswert</h3>
                                <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Gerundet (in €)"
                                               name='Wertermittlung__Vergleichwert_gerundet'>
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Vergleichsobjekte"
                                               name='Wertermittlung__VW_Vergleichsobjekte'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__VW_Vergleichsobjekte', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__VW_Vergleichsobjekte', 'Vergleichsobjekte', 'Vergleichsobjekte Vergleichswert')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Anpassungen"
                                               name='Wertermittlung__Vergleichswert_Anpassungen'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__Vergleichswert_Anpassungen', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__Vergleichswert_Anpassungen', 'Anpassungen', 'Vergleichswert Anpassungen')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                            </div>
                        )}
                        {(Ertragswert) && (
                            <div id="ertragswert" className="scrollable-section mt-[20x]">
                                <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Ertragswert</h3>
                                <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Gerundet (in €)" name='Wertermittlung__Ertragswer_gerundet'>
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Rohertrag" name='Wertermittlung__EW_RE_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__EW_RE_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__EW_RE_B', 'Rohertrag', 'Ertragswert Rohertrag')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Bewirtschaftungskosten" name='Wertermittlung__EW_BK_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__EW_BK_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__EW_BK_B', 'Bewirtschaftungskosten', 'Ertragswert Bewirtschaftungskosten')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Liegenschaftszinssatz" name='Wertermittlung__EW_LSZ_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__EW_LSZ_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__EW_LSZ_B', 'Liegenschaftszinssatz', 'Ertragswert Liegenschaftszinssatz')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Gesamtnutzungsdauer" name='Wertermittlung__EW_GND_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__EW_GND_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__EW_GND_B', 'Gesamtnutzungsdauer', 'Ertragswert Gesamtnutzungsdauer')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Restnutzungsdauer" name='Wertermittlung__EW_RND_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__EW_RND_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__EW_RND_B', 'Restnutzungsdauer', 'Ertragswert Restnutzungsdauer')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                            </div>
                        )}
                        {(Sachwert) && (
                            <div id="sachwert" className="scrollable-section mt-[20x]">
                                <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Sachwert</h3>
                                <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Gerundet (in €)" name='Wertermittlung__Sachwert_gerundet'>
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Normalherstellungskosten" name='Wertermittlung__SW_NHK_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__SW_NHK_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__SW_NHK_B', 'Normalherstellungskosten', 'Sachwert NHK')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Baupreisindex" name='Wertermittlung__SW_BPI_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__SW_BPI_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__SW_BPI_B', 'Baupreisindex', 'Sachwert Baupreisindex')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Gesamtnutzungsdauer" name='Wertermittlung__SW_GND_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__SW_GND_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__SW_GND_B', 'Gesamtnutzungsdauer', 'Sachwert Gesamtnutzungsdauer')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Restnutzungsdauer" name='Wertermittlung__SW_RND_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__SW_RND_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__SW_RND_B', 'Restnutzungsdauer', 'Sachwert Restnutzungsdauer')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Nebengebäude" name='Wertermittlung__SW_NG_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__SW_NG_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__SW_NG_B', 'Nebengebäude', 'Sachwert Nebengebäude')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Bauteile & Einrichtungen" name='Wertermittlung__SW_BuE_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__SW_BuE_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__SW_BuE_B', 'Bauteile & Einrichtungen', 'Sachwert Bauteile und Einrichtungen')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Außenanlagen" name='Wertermittlung__SW_AA_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__SW_AA_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__SW_AA_B', 'Außenanlagen', 'Sachwert Außenanlagen')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Marktanpassungen" name='Wertermittlung__SW_MA_B'>
                                        <TextArea className="textInput" autoSize={true}
                                                  ref={(element) => handleRef('Wertermittlung__SW_MA_B', element)}/>
                                    </Form.Item>
                                    <Button
                                        className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__SW_MA_B', 'Marktanpassungen', 'Sachwert Marktanpassung')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                                </div>

                            </div>
                        )}
                        <div id="bog" className="scrollable-section mt-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Besondere
                                objektspezifische Grundstücksmerkmale</h3>

                            <div
                                className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Beschreibung" name='Wertermittlung__BoG'>
                                    <TextArea className="textInput" autoSize={true}
                                              ref={(element) => handleRef('Wertermittlung__BoG', element)}/>
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__BoG', 'Beschreibung', 'Besondere objektspezifische Grundstücksmerkmale (BoG)')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                            </div>

                        </div>
                        <div id="beurteilung" className="scrollable-section mt-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Beurteilung</h3>

                            <div
                                className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Vermietbarkeit" name='Wertermittlung__Beurteilung_Vermietbarkeit'>
                                    <TextArea className="textInput" autoSize={true}
                                              ref={(element) => handleRef('Wertermittlung__Beurteilung_Vermietbarkeit', element)}/>
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__Beurteilung_Vermietbarkeit', 'Vermietbarkeit', 'Vermietbarkeit')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                            </div>

                            <div
                                className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Verkäuflichkeit"
                                           name='Wertermittlung__Beurteilung_Verkaeuflichkeit'>
                                    <TextArea className="textInput" autoSize={true}
                                              ref={(element) => handleRef('Wertermittlung__Beurteilung_Verkaeuflichkeit', element)}/>
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('Wertermittlung__Beurteilung_Verkaeuflichkeit', 'Verkäuflichkeit', 'Verkäuflichkeit')}
                                        style={{float: 'right'}}><FileTextOutlined/></Button>

                            </div>

                        </div>

                        <div
                            className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Plausibilisierung 1" name='Wertermittlung__Plausibilisierung1'>
                                <TextArea className="textInput" autoSize={true}
                                          ref={(element) => handleRef('Wertermittlung__Plausibilisierung1', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Wertermittlung__Plausibilisierung1', 'Plausibilisierung', 'Wertermittlung Plausibilisierung')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>
                        <div
                            className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Plausibilisierung 2" name='Wertermittlung__Plausibilisierung2'>
                                <TextArea className="textInput" autoSize={true}
                                          ref={(element) => handleRef('Wertermittlung__Plausibilisierung2', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Wertermittlung__Plausibilisierung2', 'Plausibilisierung', 'Wertermittlung Plausibilisierung')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>
                        <div
                            className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Plausibilisierung 3" name='Wertermittlung__Plausibilisierung3'>
                                <TextArea className="textInput" autoSize={true}
                                          ref={(element) => handleRef('Wertermittlung__Plausibilisierung3', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Wertermittlung__Plausibilisierung3', 'Plausibilisierung', 'Wertermittlung Plausibilisierung')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>


                        <div id="verkehrswert" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Verkehrswert</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Verkehrswert (in €)"
                                           name='Wertermittlung__Verkehrswert__WE_Verkehrswert'>
                                    <MyInputNumber className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Verkehrswert in Worten"
                                           name='Wertermittlung__Verkehrswert__WE_VerksehrwertiW'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>
            )}

        </div>
    );
};

export default Wertermittlung;
