import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AuthProvider from "./components/AuthProvider/AuthProvider";
import { BrowserRouter as Router } from "react-router-dom";
import "./input.css"
import { SwitchProvider } from "./pages/SwitchContext";


//import config provider from antd module
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/de_DE'; // Importiere die gewünschte Lokalisierung
import dayjs from 'dayjs'
import 'dayjs/locale/de'
dayjs.locale('de-de');


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <SwitchProvider>
            <AuthProvider>
                <Router>
                    <ConfigProvider locale={locale}>
                        <App />
                    </ConfigProvider>
                </Router>
            </AuthProvider>
        </SwitchProvider>

    </React.StrictMode>
);
