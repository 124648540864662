import {
    Button,
    Card,
    Col,
    message,
    Row,
    Anchor,
    Form,
    InputNumber,
    Modal, Select, Input, AutoComplete, Checkbox
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useEffect, useRef, useState} from "react";
import { getToken } from "../../helpers";
import {
    changeMultiSelectValues, getAPIurl,
    getInitialValuesFormular, isTextFieldActive, LoadingScreen, MyInputNumber, optimizedHandleScroll,
    postBewertungsobjekt, SaveIcon,
    updateDataContent, useModals
} from "../../helpers/formHelpers";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined, FileTextOutlined, PlusOutlined } from "@ant-design/icons";
import useScreenSize1 from "../../hooks/useScreenSize1";
import { useSwitch } from '../../pages/SwitchContext';
import { HashLink as Linki } from "react-router-hash-link";
import { RxCross2 } from "react-icons/rx";
import { RiDeleteBin5Fill } from "react-icons/ri";

const { Link } = Anchor;
const { Option } = Select;

let dataContainer;
let dataContent;
let navigate;
let enumerationValuesContent;
let updateDataContentValue;
let updateDataContentContainer;
const putBody = {};


const Gebaeude = () => {
    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize1();
    const { gutachtenID, tab, objektID } = useParams();
    const { isSwitchOn, toggleSwitch } = useSwitch();



    const [BaulicheNutzung, setBaulicheNutzung] = useState(false);
    const [FlaechenNutzung, setFlaechenNutzung] = useState(false);
    const [Gebaeude, setGebaeude] = useState(false);
    const [Ausstattung, setAusstattung] = useState(false);
    const [Haustechnik, setHaustechnik] = useState(false);
    const [BesondereBauteile, setBesondereBauteile] = useState(false);
    const [Aussenanlage, setAussenanlage] = useState(false);
    const [Zustand, setZustand] = useState(false);
    const [MietenKosten, setMietenKosten] = useState(false);
    const [Ausstattungsmerkmal, setAusstattungsmerkmal] = useState(false);
    const [Beurteilung, setBeurteilung] = useState(false);

    const stateValues = {
        BaulicheNutzung,
        FlaechenNutzung,
        Gebaeude,
        Ausstattung,
        Haustechnik,
        BesondereBauteile,
        Aussenanlage,
        Zustand,
        MietenKosten,
        Ausstattungsmerkmal,
        Beurteilung,
    };

    const stateUpdaters = {
        setBaulicheNutzung,
        setFlaechenNutzung,
        setGebaeude,
        setAusstattung,
        setHaustechnik,
        setBesondereBauteile,
        setAussenanlage,
        setZustand,
        setMietenKosten,
        setAusstattungsmerkmal,
        setBeurteilung,
    };

    useEffect(() => {

        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }
    }, [isDesktopView]);
    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };
    if (objektID != undefined) { // = Bewertungsobjekt
        return (
            <>
                <div className="w-full">
                    <div
                        className={`${!isDesktopView ? "visible  cursor-pointer" : "hidden"
                            } text-white pl-[15px]`}
                        onClick={() => {
                            setVis(!vis);
                        }}
                    >
                        <button className="menuBtn">Menu</button>
                    </div>
                    <div
                        className={`flex flex-row gap-[30px] relative mr-[15px]  mb-[40px] ${!isDesktopView ? "ml-[15px]" : ""
                            }`}
                    >
                        {vis ? (
                            <div className="w-[299px] absolute xl:relative z-10 h-full xl:h-auto  ">
                                <MenuContainerDetails
                                    vis={vis}
                                    onVisibilityChange={handleVisibilityChange}
                                    stateValues={stateValues}
                                    stateUpdaters={stateUpdaters}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="w-full ${isSwitchOn ? 'bg-black' : 'bg-white'}rounded-[30px]">
                            <FormContainerDetails
                                stateValues={stateValues}
                                stateUpdaters={stateUpdaters}/>
                        </div>
                    </div>
                </div>
            </>

        );
    } else {
        return (
            <Row>
                <Col md={1} lg={1} sm={1} xs={1}>
                </Col>
                <Col md={22} lg={22} sm={22} xs={22} style={{ paddingLeft: "15px", paddingRight: "15px" }} className="FormContainer">
                    <FormContainerUebersicht />
                </Col>
            </Row>
        );
    }
};



const FormContainerUebersicht = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { auftragID, gutachtenID, tab, objektID } = useParams();
    const { isSwitchOn, toggleSwitch } = useSwitch(); // Get the activeKey from the URL

    navigate = useNavigate();

    const fetchGebaeudeUebersicht = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(`${getAPIurl()}/gutachtens/${gutachtenID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            dataContainer = await response.json();
            dataContent = dataContainer.data.attributes;

        } catch (error) {
            console.log(error);
            message.error("Error while fetching gutachten!" + error);
        } finally {

            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchGebaeudeUebersicht().then();



    }, []);

    // Sende einen POST-Request an deine Strapi-API, um den neues Bewertungsobjekt zu erstellen
    const addBewertungsobjekt = async () => {
        try {
            if (Object.keys(dataContent.bewertungsobjekts.data).length > 0) {
                const responseBOData = await postBewertungsobjekt(gutachtenID);
                const newUrl = `/gutachten/${auftragID}/${gutachtenID}/gebaeude/${responseBOData.id}`; // => Navigate to new object
                navigate(newUrl);
            } else { // In Case, there is no Bewertungsobjekt existing, create two and stay on overview
                await postBewertungsobjekt(gutachtenID);
                await postBewertungsobjekt(gutachtenID);
                window.location.reload();
            }
        } catch (error) {
            // Hier können Sie Fehler behandeln, wenn die Anfrage fehlschlägt
            console.error("Fehler beim Senden der POST-Anfrage:", error);
        }
    }

    const deleteBewertungsobjekt = (id) => {
        Modal.confirm({
            title: 'Bewertungsobjekt löschen',
            content: 'Möchten Sie dieses Bewertungsobjekt wirklich löschen?',
            onOk() {
                deleteBewertungsobjektConfirmed(id);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    };
    async function deleteBewertungsobjektConfirmed(deleteID) {
        try {
            const response = await fetch(`${getAPIurl()}/bewertungsobjekts/${deleteID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            if (response.ok) {
                console.log(`Eintrag mit ID ${deleteID} wurde erfolgreich gelöscht.`);
                window.location.reload();
            } else {
                console.error(`Fehler beim Löschen des Eintrags: ${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            console.error('Fehler beim Löschen des Eintrags:', error);
        }
    }

    return (
        <div className={` p-[20px] rounded-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <h2 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Gebäude & Außenanlagen</h2>
            <div className="cardsBewertungsobjekt  md:pr-[10%]  md:pl-[10%]">
                {/* Hier ist Ihre Hauptkachel */}
                <Linki className={` ${isSwitchOn ? 'cardsGutachten1' : 'cardsGutachten'}`} to={`/gutachten/${auftragID}/${gutachtenID}/gebaeude/allgemein`}><Card className="w-full gutachtCard max-w-[460px]">
                    <h2 className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`}>Gutachtenübergreifende Gebäude & AA Beschreibung</h2>
                </Card></Linki>
                <hr />
                <Row gutter={16} className="mt-[20px]">
                    {/* Hier sind die kleineren Kacheln */}
                    {dataContent && dataContent.bewertungsobjekts && dataContent.bewertungsobjekts.data.map((entry, index) => (
                        <Col className={` ${isSwitchOn ? 'cardsGutachten1' : 'cardsGutachten'} flex flex-wrap`} key={entry.id}> {/* Achten Sie auf den 'key'-Prop für die Iteration */}
                            <Card className="w-[320px]">
                                <div style={{ float: 'right', cursor: 'pointer' }} onClick={() => deleteBewertungsobjekt(entry.id)}>
                                    <RiDeleteBin5Fill className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`} />
                                </div>
                                <Linki to={`/gutachten/${auftragID}/${gutachtenID}/gebaeude/${entry.id}`}>
                                    <h2 className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`}>Bewertungsobjekt {index + 1}<br /><b>{entry.attributes.Name_BO}</b></h2>
                                </Linki>
                            </Card>
                        </Col>
                    ))}


                    <Col span={8}>
                        <Card className="w-[320px]">
                            <Linki to={"#"} className={`${isSwitchOn ? 'cardsGutachten1' : 'cardsGutachten'}`} onClick={() => addBewertungsobjekt()}>
                                <h2 className={` ${isSwitchOn ? 'whiteNew' : 'text-white'}`}><PlusOutlined /> Bewertungsobjekt anlegen</h2>
                            </Linki>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};


const MenuContainerDetails = ({ vis, onVisibilityChange, stateValues }) => {
    const { auftragID, gutachtenID, tab, objektID } = useParams(); // Get the activeKey from the URL
    window.addEventListener('scroll', optimizedHandleScroll);
    const [isInLower20, setIsInLower20] = useState(false);
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const handleClick = () => {
        const newVisibility = !vis;
        onVisibilityChange(newVisibility);
    };
    const { BaulicheNutzung,
        FlaechenNutzung,
        Gebaeude,
        Ausstattung,
        Haustechnik,
        BesondereBauteile,
        Aussenanlage,
        Zustand,
        MietenKosten,
        Ausstattungsmerkmal,
        Beurteilung } = stateValues;
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Check if the device height is less than 600px
            const isDeviceHeightBelow600 = window.innerHeight < 650;

            // Calculate the scroll position percentage
            const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

            // Set the state based on the scroll position and device height
            setIsInLower20(isDeviceHeightBelow600 && scrollPercentage >= 80);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };


    }, []);
    return (
        <div className={`sidebar rounded-r-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <div className={`ml-[197px]`}><RxCross2 onClick={handleClick} className={`xl:hidden ${isSwitchOn ? 'text-white' : 'text-black'} text-[30px] mb-[20px] `} /></div>
            <Linki to={`/gutachten/${auftragID}/${gutachtenID}/gebaeude/`}>
                <Button className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} border-none ml-[16px] rounded-full buttonHover1`} type="primary">Zurück zur Übersicht</Button>
            </Linki>
            <Anchor affix={false} offsetTop={0} className={`your-component ${isInLower20 ? 'lower-30' : ''}`}>
                {(BaulicheNutzung || objektID == "allgemein") && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#art-und-mass" title="Art und Mass der baulichen Nutzung" />)}
                {(FlaechenNutzung || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#flaechen-und-nutzung" title="Flächen und Nutzung" />)}
                {(Gebaeude || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#gebaeude" title="Gebäude" />)}
                {(Ausstattung || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#ausstattung"  title="Ausstattung" />)}
                {(Haustechnik || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#haustechnik" title="Haustechnik" />)}
                {(BesondereBauteile || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#besondere-bauteile"  title="Besondere Bauteile" />)}
                {(Aussenanlage || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#aussenanlagen" title="Außenanlagen" />)}
                {(Zustand || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#zustand"  title="Zustand" />)}
                {(MietenKosten || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#miete-kosten"  title="Mieten und Kosten" />)}
                {(Ausstattungsmerkmal || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#ausstattungsmerkmal"  title="Ausstattungsmerkmal" />)}
                {(Beurteilung || objektID == "allgemein")  && (<Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#beurteilung" title="Beurteilung" />)}
            </Anchor>
        </div>
    );
};

const FormContainerDetails = ({ stateValues, stateUpdaters }) => {
    const [loading, setIsLoading] = useState(false);
    const [saveIcon, setSaveIcon] = useState(false);
    const [saveIcon1, setSaveIcon1] = useState(false);
    const { gutachtenID, tab, objektID } = useParams(); // Get the activeKey from the URL
    const [formFormular1] = Form.useForm();
    const inputRefs = useRef({});
    const handleRef = (name, element) => {
        if (element) {
            inputRefs.current[name] = element;
        }
    };
    const [selectedOption, setSelectedOption] = useState(null);
    const [cards, setCards] = useState([]);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [dataContentRefresh, setDataContent] = useState(false);
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [initialValues, setInitialValuesState] = useState(false);
    const { modals, handleOpenTextbausteine, handleCloseTextbausteine, renderModals, selectedTextBausteine } = useModals(); //Drawer for Textbausteine
    // Destructure individual state values
    const { BaulicheNutzung,
        FlaechenNutzung,
        Gebaeude,
        Ausstattung,
        Haustechnik,
        BesondereBauteile,
        Aussenanlage,
        Zustand,
        MietenKosten,
        Ausstattungsmerkmal,
        Beurteilung } = stateValues;
    // Destructure individual state updater functions
    const {         setBaulicheNutzung,
        setFlaechenNutzung,
        setGebaeude,
        setAusstattung,
        setHaustechnik,
        setBesondereBauteile,
        setAussenanlage,
        setZustand,
        setMietenKosten,
        setAusstattungsmerkmal,
        setBeurteilung } = stateUpdaters;


    const handleSelectChange = (value) => {
        setSelectedOption(value);
    };

    // Update Collection Gutachten
    const updateGutachten = (putBody, forceReload = false, forcePageReload = false) => {
        setSaveIcon(true);
        setSaveIcon1(true)
        fetch(`${getAPIurl()}/gutachtens/${gutachtenID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(putBody),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update data.');
                }
                //console.log('putBody:', putBody);
                return response.json();
            })
            .then((responseData) => {
                // New component (undefined item.id) => reload;
                if (forceReload) {
                    if (forcePageReload == true) {
                        // window.location.reload();  // This should be eliminated, just temporarly
                    } else {
                        setUpdateTrigger((prev) => !prev);
                    }
                }
                // Setze das Speicher-Icon nach X Millisekunden zurück
                setTimeout(() => { setSaveIcon(false); }, 300);
                setTimeout(() => {
                    setSaveIcon1(false);
                }, 3000);
            })
            .catch((error) => {
                console.log('putBody:', putBody);
                console.error('Error:', error);
            });
    }
    // Update Collection Bewertungsobjekt
    const updateBewertungsobjekt = (putBody, forceReload = false, forcePageReload = false) => {
        if (forcePageReload) {
            setIsLoading(true);
        } else {
            setSaveIcon(true);
            setSaveIcon1(true)
        }
        fetch(`${getAPIurl()}/bewertungsobjekts/${objektID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(putBody),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update data.');
                }
                //console.log('putBody:', putBody);
                return response.json();
            })
            .then((responseData) => {
                // New component (undefined item.id) => reload;
                if (forceReload) {
                    if (forcePageReload == true) {
                        //window.location.reload();
                    } else {
                        setUpdateTrigger((prev) => !prev);
                    }
                }
                setIsLoading(false);
                // Setze das Speicher-Icon nach X Millisekunden zurück
                setTimeout(() => { setSaveIcon(false); }, 300);
                setTimeout(() => {
                    setSaveIcon1(false);
                }, 3000);
            })
            .catch((error) => {
                console.log('putBody:', putBody);
                console.error('Error:', error);
            });
    }

    const fetchGebaeudeDetails = async (jumptoanchor = true) => {
        setIsLoading(true);


        try {

            const enumerationValues = await fetch(`${getAPIurl()}/bewertungsobjekt/enumvalues`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            enumerationValuesContent = await enumerationValues.json();

            if (objektID == "allgemein") { // Gutachtenübergreifend => Collection Gutachten
                const response = await fetch(`${getAPIurl()}/gutachtens/${gutachtenID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                    headers: {
                        // set the auth token to the user's jwt
                        Authorization: `Bearer ${getToken()}`,
                    },
                });
                dataContainer = await response.json();
                dataContent = dataContainer.data.attributes;
                setInitialValuesState(getInitialValuesFormular(dataContent));
                if (dataContent.Gebaeude_AA.Ausstattungsmerkmal) {
                    setCards(dataContent.Gebaeude_AA.Ausstattungsmerkmal);
                }
            } else { // Einzelnes Bewertungsobjekt => Collection Bewertungsobjekt
                const response = await fetch(`${getAPIurl()}/bewertungsobjekts/${objektID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                    headers: {
                        // set the auth token to the user's jwt
                        Authorization: `Bearer ${getToken()}`,
                    },
                });
                dataContainer = await response.json();
                dataContent = dataContainer.data.attributes;
                setInitialValuesState(getInitialValuesFormular(dataContent));
                if (dataContent.Gebaeude_AA.Ausstattungsmerkmal) {
                    setCards(dataContent.Gebaeude_AA.Ausstattungsmerkmal);
                }
                showAbschnitt("",false,true);
            }


            //console.log(enumerationValuesContent);
        } catch (error) {
            console.log(error);
            message.error("Error while fetching gutachten!" + error);
        } finally {
            setDataContent(true);

            //When everything is loaded jump to anchor (if selected)
            let currentUrl = window.location.href.split("#");
            if(currentUrl[1] && jumptoanchor == true){ // Anchor(=Hashtag) is in URL
                document.querySelector('a[href="#'+currentUrl[1]+'"]').click();
            }

            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(saveIcon){
            fetchGebaeudeDetails(false).then(); //No jump to anchor if an update of data triggers reload of data
        } else {
            fetchGebaeudeDetails().then();
        }
        const interval = setInterval(() => {
            if(!isTextFieldActive()) { //Not if currently in a textfield
                fetchGebaeudeDetails().then();
            }
        }, 30000); // Every 30 Seconds (in case multiple persons are working OR multiple tabs)
        return () => clearInterval(interval);

    }, [updateTrigger]);
    const triggerFocus = (name) => {
        if (inputRefs.current[name]) {
            inputRefs.current[name].focus();
        }
    };
    useEffect(() => {
        if (selectedTextBausteine) {
            formFormular1.setFieldsValue({ [modals[modals.length - 1].field]: selectedTextBausteine });
            triggerFocus(modals[modals.length - 1].field);
        }
    }, [selectedTextBausteine]); // Specify the dependencies to watch for changes

    const addCard = () => {

        const newCard = {
            Geschoss: '',
            Nutzungsart: '',
            Ausstattungsbeschreibung: '',
        };
        document.getElementById("button_new_component").hidden = true; // Button "Neue Komponente" verstecken, da nur eins im Status "undefined" existieren darf
        setCards([...cards, newCard]);
    };

    const deleteCard = (component, index, id) => {
        Modal.confirm({
            title: 'Ausstattungsmerkmal löschen',
            content: 'Möchten Sie dieses Ausstattungsmerkmal wirklich löschen?',
            onOk() {
                deleteCardConfirmed(component, index, id);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    };
    const deleteCardConfirmed = (component, index, id) => {
        const updatedCards = [...cards];
        updatedCards.splice(index, 1); // Remove the card at the specified index
        setCards(updatedCards);
        updateDataContent(component, index, dataContent, "deleteComponent");
        putBody.data = dataContent;
        updateGutachten(putBody);
    };

        const showAbschnitt = (value, checked,initialLoad) => {
            const mapping = {
                "BaulicheNutzung": setBaulicheNutzung,
                "FlaechenNutzung": setFlaechenNutzung,
                "Gebaeude": setGebaeude,
                "Ausstattung": setAusstattung,
                "Haustechnik": setHaustechnik,
                "BesondereBauteile": setBesondereBauteile,
                "Aussenanlage": setAussenanlage,
                "Zustand": setZustand,
                "MietenKosten": setMietenKosten,
                "Ausstattungsmerkmal": setAusstattungsmerkmal,
                "Beurteilung": setBeurteilung
            };

            const Gebaeude_AbwArr = dataContent.Gebaeude_Abw;
            if(initialLoad && Gebaeude_AbwArr != null){ // Inital load of values
                Object.keys(mapping).forEach(key => {
                    mapping[key](Gebaeude_AbwArr.includes(key));
                });
            } else if(!initialLoad){
                if (checked) {
                    Object.keys(mapping).forEach(key => {
                        if (value == key) {
                            mapping[key](true);
                        }
                    });
                } else {
                    Object.keys(mapping).forEach(key => {
                        if (value == key) {
                            mapping[key](false);
                        }
                    });
                }
            }
        }
        useEffect(() => formFormular1.resetFields(), [initialValues]);

    return (
        <div className={`${isSwitchOn ? 'bg-black' : 'bg-white'} sm:p-[20px] p-[10px] rounded-[30px]`}>
            {loading && <LoadingScreen />}
            {saveIcon1 && <SaveIcon saveIcon={saveIcon} />}
            {renderModals()}
            {dataContentRefresh && dataContent && initialValues && enumerationValuesContent && ( // Bedingung überprüft, ob dataContent & enumerationValuesContent bereits vorhanden ist

                <Form
                    form={formFormular1}
                    onBlur={(e) => {
                        // Hier wird das onBlur-Ereignis für das gesamte Formular abgefangen
                        if (e.target.type != "button") { //Not at addCard
                            const updateByIDArray = ["Gebaeude_AA__Ausstattungsmerkmal"];
                            updateDataContentContainer = dataContent;
                            let targetID;
                            if (e.target.type == "search") {
                                updateDataContentValue = selectedOption;
                                setSelectedOption(null); //Reset (for the case the next select is choosen without value)
                                targetID = e.target.id;
                            } else if (e.target.type === "checkbox") {
                                const curValues = getInitialValuesFormular(dataContent);
                                updateDataContentValue = changeMultiSelectValues(curValues.Gebaeude_Abw, e.target.value, e.target.checked);
                                targetID = e.target.parentElement.parentElement.parentElement.id;
                            } else {
                                updateDataContentValue = e.target.value;
                                targetID = e.target.id;
                            }
                            if (updateByIDArray.some(value => e.target.id.includes(value))) {
                                updateDataContent(targetID, updateDataContentValue, updateDataContentContainer, "updateByID");
                            } else {
                                updateDataContent(targetID, updateDataContentValue, updateDataContentContainer, "updateByIndex");
                            }
                            putBody.data = dataContent;
                            if (objektID == "allgemein") {
                                if (e.target.id.includes("undefined")) {
                                    //  console.log(putBody);
                                    updateGutachten(putBody, true, false);//ForceReload
                                    const elements = document.getElementsByClassName("button_new_component");
                                    for (let i = 0; i < elements.length; i++) {
                                        elements[i].hidden = false;
                                    }
                                } else {
                                    updateGutachten(putBody, false);
                                }
                            } else {
                                if (e.target.id.includes("undefined")) {
                                    //  console.log(putBody);
                                    setIsLoading(true);
                                    updateBewertungsobjekt(putBody, true, true);//ForceReload
                                } else {
                                    updateBewertungsobjekt(putBody, false);
                                }
                            }
                        }
                    }}
                    initialValues={initialValues}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    layout="horizontal"
                    // style={{ maxWidth: 800 }}
                >

                    {objektID !== "allgemein" ? (
                        <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Name Bewertungsobjekt" name='Name_BO'>
                                <Input className="inputsAll"/>
                            </Form.Item>
                            <hr/>
                        </div>
                    ) : (
                        <div
                            className={`${isSwitchOn ? 'text-white' : 'text-black'} font-bold text-16px  md:text-[28px] mb-[20px] generalText`}>
                            Gutachtenübergreifende Gebäude & AA Beschreibung
                            <hr/>
                        </div>
                    )}

                    <h2 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Gebäude
                        & Außenanlagen</h2>
                    <div>
                        {objektID !== "allgemein"  && (
                        <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item name="Gebaeude_Abw" label="Abweichende Werte">
                                <Checkbox.Group>
                                    <Checkbox value="BaulicheNutzung" checked={BaulicheNutzung}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Art und Mass der baulichen Nutzung</span>
                                    </Checkbox><br/>
                                    <Checkbox value="FlaechenNutzung" checked={FlaechenNutzung}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Flächen und Nutzung</span>
                                    </Checkbox><br/>
                                    <Checkbox value="Gebaeude" checked={Gebaeude}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Gebäude</span>
                                    </Checkbox><br/>
                                    <Checkbox value="Ausstattung" checked={Ausstattung}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Ausstattung</span>
                                    </Checkbox><br/>
                                    <Checkbox value="Haustechnik" checked={Haustechnik}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Haustechnik</span>
                                    </Checkbox><br/>
                                    <Checkbox value="BesondereBauteile" checked={BesondereBauteile}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Besondere Bauteile</span>
                                    </Checkbox><br/>
                                    <Checkbox value="Aussenanlage" checked={Aussenanlage}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Außenanlage</span>
                                    </Checkbox><br/>
                                    <Checkbox value="Zustand" checked={Zustand}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Zustand</span>
                                    </Checkbox><br/>
                                    <Checkbox value="MietenKosten" checked={MietenKosten}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Mieten und Kosten</span>
                                    </Checkbox><br/>
                                    <Checkbox value="Ausstattungsmerkmal" checked={Ausstattungsmerkmal}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Ausstattungsmerkmal</span>
                                    </Checkbox><br/>
                                    <Checkbox value="Beurteilung" checked={Beurteilung}
                                              onClick={(e) => showAbschnitt(e.target.value, e.target.checked)}><span
                                        className={`text-[16px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Beurteilung</span>
                                    </Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                        </div>
                        )}
                        {(BaulicheNutzung || objektID == "allgemein") && (
                        <div id="art-und-mass" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Art
                                und Mass der baulichen Nutzung</h3>
                                <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Art des Gebäudes" name="Gebaeude_AA__AM_ArtGebauedeV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_AM_ArtGebauede?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Art des Gebäudes"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>
                            </Form.Item>
                            <Form.Item label="Geschosse" name="Gebaeude_AA__AM_GeschosseV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_AM_Geschosse?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Geschosse"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>
                            </Form.Item>
                            <Form.Item label="Unterkellerung" name="Gebaeude_AA__AM_UnterkellerungV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_AM_Unterkellerung?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Unterkellerung"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Dachgeschoss" name="Gebaeude_AA__AM_DachgeschossV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_AM_Dachgeschoss?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Dachgeschoss"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Dachform" name="Gebaeude_AA__AM_DachformV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_AM_Dachform?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Dachform"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Nebengebäude" name="Gebaeude_AA__AM_NebengebaeudeV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_AM_Nebengebaeude?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Nebengebäude"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                        </div>
                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Sanierung / Modernisierung"
                                       name='Gebaeude_AA__AM_Sanierung_Modernisierung'>
                                <TextArea className="textInput" autoSize={true}
                                          ref={(element) => handleRef('Gebaeude_AA__AM_Sanierung_Modernisierung', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__AM_Sanierung_Modernisierung', 'Sanierung / Modernisierung', 'Sanierung/Modernisierung')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>
                        <div className={`allgemeineClass mt-[20px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Wohneinheiten" name='Gebaeude_AA__Einheiten_KGA__AM_Wohneinheiten'>
                                <MyInputNumber className="inputsAll"/>
                            </Form.Item>
                            <Form.Item label="Gewerbeeinheiten" name='Gebaeude_AA__Einheiten_KGA__AM_Gewerbeeinheiten'>
                                <MyInputNumber className="inputsAll"/>
                            </Form.Item>
                            <Form.Item label="Einheiten Gesamt" name='Gebaeude_AA__Einheiten_KGA__AM_EinheitenGes'>
                                <MyInputNumber className="inputsAll"/>
                            </Form.Item>
                        </div>
                    </div>
                        )}
                        {(FlaechenNutzung || objektID == "allgemein")  && (
                    <div id="flaechen-und-nutzung" className="scrollable-section">
                        <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Flächen
                            und Nutzung</h3>
                        <div
                            className="flex flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px] 2xl:max-w-full ">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Baujahr">
                                    <Form.Item name="Gebaeude_AA__Baujahr">
                                        <Input className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[10px] ml-auto mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__Baujahr_DDV">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.com_Baujahr_DD?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>
                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Wohnfläche">
                                    <Form.Item name="Gebaeude_AA__WF">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>

                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__WF_DD1V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_WF_DD1?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Format"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                            <div
                                className="2xl:ml-[10px] 2xl:mt-[0] mt-[-18px] mr-[-13px] xl:mr-0  xl:mt-[-34px] ml-0 md:ml-auto">
                                <Form.Item name="Gebaeude_AA__WF_DD2V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_WF_DD2?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>


                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Wohnfläche Sondereigentum">
                                    <Form.Item name="Gebaeude_AA__WFSE">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>

                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__WFSE_DD1V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_WFSE_DD1?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Format"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                            <div
                                className="2xl:ml-[10px] 2xl:mt-[0] mt-[-18px] mr-[-13px] xl:mr-0  xl:mt-[-34px] ml-0 md:ml-auto">

                                <Form.Item name="Gebaeude_AA__WFSE_DD2V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_WFSE_DD2?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>


                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Nutzfläche">
                                    <Form.Item name="Gebaeude_AA__NF">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__NF_DD1V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_WF_DD1?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Format"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                            <div
                                className="2xl:ml-[10px] 2xl:mt-[0] mt-[-18px] mr-[-13px] xl:mr-0  xl:mt-[-34px] ml-0 md:ml-auto">
                                <Form.Item name="Gebaeude_AA__NF_DD2V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_WF_DD2?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>

                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Nutzfläche Sondereigentum">
                                    <Form.Item name="Gebaeude_AA__NFSE">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__NFSE_DD1V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_NFSE_DD1?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Format"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                            <div
                                className="2xl:ml-[10px] 2xl:mt-[0] mt-[-18px] mr-[-13px] xl:mr-0  xl:mt-[-34px] ml-0 md:ml-auto">
                                <Form.Item name="Gebaeude_AA__NFSE_DD2V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_NFSE_DD2?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>


                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Wohn- und Nutzfläche">
                                    <Form.Item name="Gebaeude_AA__WFNF">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__WFNF_DD1V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_WFNF_DD1?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Format"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                            <div
                                className="2xl:ml-[10px] 2xl:mt-[0] mt-[-18px] mr-[-13px] xl:mr-0  xl:mt-[-34px] ml-0 md:ml-auto">
                                <Form.Item name="Gebaeude_AA__WFNF_DD2V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_WFNF_DD2?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>


                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Überbaute Fläche">
                                    <Form.Item name="Gebaeude_AA__UBF">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__UBF_DD1V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_UBF_DD1?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Format"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                            <div
                                className="2xl:ml-[10px] 2xl:mt-[0] mt-[-18px] mr-[-13px] xl:mr-0  xl:mt-[-34px] ml-0 md:ml-auto">
                                <Form.Item name="Gebaeude_AA__UBF_DD2V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_UBF_DD2?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>

                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Grundfläche">
                                    <Form.Item name="Gebaeude_AA__GF">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__GF_DD1V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_GF_DD1?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Format"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                            <div
                                className="2xl:ml-[10px] 2xl:mt-[0] mt-[-18px] mr-[-13px] xl:mr-0  xl:mt-[-34px] ml-0 md:ml-auto">
                                <Form.Item name="Gebaeude_AA__GF_DD2V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_GF_DD2?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>

                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Geschossfläche">
                                    <Form.Item name="Gebaeude_AA__GSF">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__GSF_DD1V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_GSF_DD1?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Format"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                            <div
                                className="2xl:ml-[10px] 2xl:mt-[0] mt-[-18px] mr-[-13px] xl:mr-0  xl:mt-[-34px] ml-0 md:ml-auto">
                                <Form.Item name="Gebaeude_AA__GSF_DD2V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_GSF_DD2?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>

                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Bruttogrundfläche">
                                    <Form.Item name="Gebaeude_AA__BGF">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__BGF_DD1V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_BGF_DD1?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Format"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                            <div
                                className="2xl:ml-[10px] 2xl:mt-[0] mt-[-18px] mr-[-13px] xl:mr-0  xl:mt-[-34px] ml-0 md:ml-auto">
                                <Form.Item name="Gebaeude_AA__BGF_DD2V">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_BGF_DD2?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>


                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Grundflächenzahl">
                                    <Form.Item name="Gebaeude_AA__GRZ">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__GRZ_DDV">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_GRZ_DD?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>

                        <div
                            className="flex xl:flex-row flex-col flex-wrap w-auto max-w-[320px] md:max-w-[604px] xl:max-w-[890px]  2xl:max-w-full">
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Geschossflächenzahl">
                                    <Form.Item name="Gebaeude_AA__GFZ">
                                        <MyInputNumber className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className="xl:ml-[14px] ml-auto xl:mr-0 mr-[-13px] mt-[-40px] xl:mt-[0]">
                                <Form.Item name="Gebaeude_AA__GFZ_DDV">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                        options={enumerationValuesContent.com_GFZ_DD?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Angabe"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Nutzungsart" name='Gebaeude_AA__AM_Nutzungsart'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__AM_Nutzungsart', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__AM_Nutzungsart', 'Nutzungsart', 'Nutzungsart')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>
                    </div>
                            )}
                        {(Gebaeude || objektID == "allgemein")  && (
                    <div id="gebaeude" className="scrollable-section">
                        <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Gebäude</h3>
                        <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Konstruktionsart"
                                       name="Gebaeude_AA__Gebauede_Aussenanlage__GE_KonstruktionsartV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Konstruktionsart?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Konstruktionsart"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Fundamente" name="Gebaeude_AA__Gebauede_Aussenanlage__GE_FundamenteV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Fundamente?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Fundamente"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Sockel" name="Gebaeude_AA__Gebauede_Aussenanlage__GE_SockelV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Sockel?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Sockel"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Außenwände" name='Gebaeude_AA__Gebauede_Aussenanlage__GE_Aussenwaende'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__GE_Aussenwaende', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__GE_Aussenwaende', 'Außenwände', 'Außenwände')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Fassade" name='Gebaeude_AA__Gebauede_Aussenanlage__GE_Fassade'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__GE_Fassade', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__GE_Fassade', 'Fassade', 'Fassade')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Innenwände" name='Gebaeude_AA__Gebauede_Aussenanlage__GE_Innenwaende'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__GE_Innenwaende', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__GE_Innenwaende', 'Innenwände', 'Innenwände')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>
                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Geschossdecken"
                                       name='Gebaeude_AA__Gebauede_Aussenanlage__GE_Geschossdecken'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__GE_Geschossdecken', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__GE_Geschossdecken', 'Geschossdecken', 'Geschossdecken')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Treppenhaus & Treppen"
                                       name='Gebaeude_AA__Gebauede_Aussenanlage__GE_Treppenhaus_Treppen'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__GE_Treppenhaus_Treppen', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__GE_Treppenhaus_Treppen', 'Treppenhaus & Treppen', 'Treppenhaus/Treppen')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>
                        <div className={`mt-[20px] allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Dachkonstruktion"
                                       name="Gebaeude_AA__Gebauede_Aussenanlage__GE_DachkonstruktionV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Dachkonstruktion?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Dachkonstruktion"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Dachform" name="Gebaeude_AA__Gebauede_Aussenanlage__GE_DachformV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Dachform?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Dachform"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Dacheindeckung"
                                       name="Gebaeude_AA__Gebauede_Aussenanlage__GE_DacheindeckungV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Dacheindeckung?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Dacheindeckung"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Dachaufbauten"
                                       name="Gebaeude_AA__Gebauede_Aussenanlage__GE_DachaufbautenV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Dachaufbauten?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Dachaufbauten"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Dachausbau" name="Gebaeude_AA__Gebauede_Aussenanlage__GE_DachausbauV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Dachausbau?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Dachausbau"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Spenglerarbeiten"
                                       name="Gebaeude_AA__Gebauede_Aussenanlage__GE_SpenglerarbeitenV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Spenglerarbeiten?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Spenglerarbeiten"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Hauseingang" name="Gebaeude_AA__Gebauede_Aussenanlage__GE_HauseingangV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Hauseingang?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Hauseingang"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Keller" name="Gebaeude_AA__Gebauede_Aussenanlage__GE_KellerV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.sub_com_GE_Keller?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Keller"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Sonstiges" name='Gebaeude_AA__Gebauede_Aussenanlage__GE_Sonstiges'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__GE_Sonstiges', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__GE_Sonstiges', 'Sonstiges Gebäude', 'Sonstiges (Gebäude)')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                    </div>
                            )}
                        {(Ausstattung || objektID == "allgemein")  && (
                    <div id="ausstattung" className="scrollable-section">
                        <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Ausstattung</h3>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Haus- & Wohnungstüren" name='Gebaeude_AA__Gebauede_Aussenanlage__AS_HWT'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__AS_HWT', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__AS_HWT', 'Haus- & Wohnungstüren', 'Haus-/Wohnungstüren')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>
                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Innentüren" name='Gebaeude_AA__Gebauede_Aussenanlage__AS_IT'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__AS_IT', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__AS_IT', 'Innentüren', 'Innentüren')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Fenster & Balkon"
                                       name='Gebaeude_AA__Gebauede_Aussenanlage__AS_FBTT'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__AS_FBTT', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__AS_FBTT', 'Fenster & Balkon-/Terassentüren', 'Fenster, Balkon-/Terassentüren')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>


                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Fußbodenbeläge" name='Gebaeude_AA__Gebauede_Aussenanlage__AS_FBB'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__AS_FBB', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__AS_FBB', 'Fußbodenbeläge', 'Fußbodenbeläge')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Wandbeläge" name='Gebaeude_AA__Gebauede_Aussenanlage__AS_WB'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__AS_WB', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__AS_WB', 'Wandbeläge', 'Wandbeläge')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Deckenbeläge" name='Gebaeude_AA__Gebauede_Aussenanlage__AS_DB'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__AS_DB', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__AS_DB', 'Deckenbeläge', 'Deckenbeläge')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Küchen" name='Gebaeude_AA__Gebauede_Aussenanlage__AS_K'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__AS_K', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__AS_K', 'Küchen', 'Küchen')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Sanitäre Anlagen" name='Gebaeude_AA__Gebauede_Aussenanlage__AS_SA'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__AS_SA', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__AS_SA', 'Sanitäre Anlagen', 'Sanitäre Anlagen')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Sonstiges" name='Gebaeude_AA__Gebauede_Aussenanlage__AS_Sonstiges'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Gebauede_Aussenanlage__AS_Sonstiges', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Gebauede_Aussenanlage__AS_Sonstiges', 'Sonstiges Ausstattung', 'Sonstiges (Ausstattung)')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                    </div>
                            )}
                        {(Haustechnik || objektID == "allgemein")  && (
                    <div id="haustechnik" className="scrollable-section">
                        <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Haustechnik</h3>
                        <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Heizung" name="Gebaeude_AA__HT_HeizungV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_HT_Heizung?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Heizung"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Heizungstyp" name="Gebaeude_AA__HT_HeizungTypV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_HT_HeizungTyp?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Heizungstyp"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Baujahr Heizung" name='Gebaeude_AA__HT_HeizungBaujahr'>
                                <Input className="inputsAll"/>
                            </Form.Item>
                            <Form.Item label="Warmwasserversorgung" name="Gebaeude_AA__HT_WarmwasserversorgungV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_HT_Warmwasserversorgung?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Warmwasserversorgung"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Warmwasserversorgung Typ" name="Gebaeude_AA__HT_WarmwasserVTypV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_HT_WarmwasserVTyp?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Warmwasserversorgung Typ"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Warmwasserversorgung Baujahr" name='Gebaeude_AA__HT_WarmwasserVBaujahr'>
                                <Input className="inputsAll"/>
                            </Form.Item>
                            <Form.Item label="Konvektion" name="Gebaeude_AA__HT_KonvektionV">
                                <AutoComplete
                                className={"AutoCompleteTextArea"}
                                options={enumerationValuesContent.com_HT_Konvektion?.map(
                                    (option, index) => ({value: option, key: index})
                                )}
                                filterOption={(inputValue, option) =>
                                    option.value
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !== -1
                                }
                                onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Konvektion"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Elektroinstallation" name="Gebaeude_AA__HT_ElektroinstallationV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_HT_Elektroinstallation?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Elektroinstallation"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Sonstiges" name='Gebaeude_AA__HT_Sonstiges'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__HT_Sonstiges', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__HT_Sonstiges', 'Sonstiges Haustechnik', 'Sonstiges (Haustechnik)')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                    </div>
                            )}
                        {(BesondereBauteile || objektID == "allgemein")  && (
                    <div id="besondere-bauteile" className="scrollable-section">
                        <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Besondere
                            Bauteile</h3>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Besondere Bauteile" name='Gebaeude_AA__GE_BesondereBauteile'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__GE_BesondereBauteile', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__GE_BesondereBauteile', 'Besondere Bauteile', 'Besondere Bauteile')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Besondere Einrichtungen" name='Gebaeude_AA__GE_BesondereEinrichtung'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__GE_BesondereEinrichtung', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__GE_BesondereEinrichtung', 'Besondere Einrichtungen', 'Besondere Einrichtungen')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                    </div>
                            )}
                        {(Aussenanlage || objektID == "allgemein")  && (
                    <div id="aussenanlagen" className="scrollable-section mt-[20px]">
                        <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Außenanlagen</h3>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Beschreibung" name='Gebaeude_AA__AU_Beschreibung'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__AU_Beschreibung', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__AU_Beschreibung', 'Beschreibung Außenanlagen', 'Beschreibung')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Zuwegung" name='Gebaeude_AA__AU_Zuwegung'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__AU_Zuwegung', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__AU_Zuwegung', 'Zuwegung', 'Zuwegung')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Einfriedung" name='Gebaeude_AA__AU_Einfriedung'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__AU_Einfriedung', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__AU_Einfriedung', 'Einfriedung', 'Einfriedung')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>


                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Garagen" name='Gebaeude_AA__AU_Garagen'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__AU_Garagen', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__AU_Garagen', 'Garagen', 'Garagen')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Stellplätze" name='Gebaeude_AA__AU_Stellplaetze'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__AU_Stellplaetze', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__AU_Stellplaetze', 'Stellplätze', 'Stellplätze')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                    </div>
                            )}
                        {(Zustand || objektID == "allgemein")  && (
                    <div id="zustand" className="scrollable-section mt-[20px]">
                        <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Zustand</h3>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Grundrissgestaltung" name='Gebaeude_AA__ZU_Grundrissgestaltung'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__ZU_Grundrissgestaltung', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__ZU_Grundrissgestaltung', 'Grundrissgestaltung', 'Grundrissgestaltung')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Bauschäuden / -mängel" name='Gebaeude_AA__ZU_BauschaedenBaumaengel'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__ZU_BauschaedenBaumaengel', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__ZU_BauschaedenBaumaengel', 'Bauschäuden / -mängel', 'Bauschäden und Baumängel')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>
                        <div className={`allgemeineClass mt-[20px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Ausrichtung" name="Gebaeude_AA__ZU_AusrichtungV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_ZU_Ausrichtung?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Ausrichtung"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                            <Form.Item label="Belichtung & Besonnung" name="Gebaeude_AA__ZU_BelichtungBesonnungV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.com_ZU_BelichtungBesonnung?.map(
                                        (option, index) => ({value: option, key: index})
                                    )}
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Belichtung & Besonnung"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>

                            </Form.Item>
                        </div>
                    </div>
                            )}
                        {(MietenKosten || objektID == "allgemein")  && (
                    <div id="miete-kosten" className="scrollable-section ">
                        <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Miete
                            und Kosten</h3>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Miete" name='Gebaeude_AA__MieteUndKosten__MK_Miete'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__MieteUndKosten__MK_Miete', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__MieteUndKosten__MK_Miete', 'Miete', 'Miete')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Kosten" name='Gebaeude_AA__MieteUndKosten__MK_Kosten'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__MieteUndKosten__MK_Kosten', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__MieteUndKosten__MK_Kosten', 'Kosten', 'Kosten')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                    </div>
                            )}
                        {(Ausstattungsmerkmal || objektID == "allgemein")  && (
                            <div id="ausstattungsmerkmal"
                                 className="scrollable-section border-t border-gray-300 mt-[20px]">
                                <div
                                    className="flex md:flex-row flex-col justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                    <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Ausstattungsmerkmal</h3>

                                </div>

                                {cards.map((entry, index) => (
                                    <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                          size="small"
                                          key={index}>
                                        <div
                                            className={`flex justify-center  sacherCenterText ${isSwitchOn ? 'maxColor' : ''}`}>
                                            <Form.Item label={`Geschoss:`}
                                                       name={`Gebaeude_AA__Ausstattungsmerkmal__${entry.id}__GeschossV`}>
                                                <AutoComplete
                                                    className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                    options={enumerationValuesContent.sub_com_Geschoss?.map(
                                                        (option, index) => ({value: option, key: index})
                                                    )}
                                                    filterOption={(inputValue, option) =>
                                                        option.value
                                                            .toUpperCase()
                                                            .indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    onChange={handleSelectChange} // Add your change handler if needed
                                                >
                                                    <TextArea
                                                        placeholder="Geschoss"
                                                        autoSize={true}
                                                        className="textInput"
                                                    />
                                                </AutoComplete>

                                            </Form.Item>
                                            <div style={{float: 'right', cursor: 'pointer'}}
                                                 className=" md:mt-[0] ml-[10px]"
                                                 onClick={() => deleteCard("Gebaeude_AA.Ausstattungsmerkmal", index, entry.id)}>
                                                <DeleteOutlined className="text-[#FF2E3B]"/>
                                            </div>
                                        </div>

                                        <div
                                            className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                            <Form.Item label={`Nutzungsart: `}
                                                       name={`Gebaeude_AA__Ausstattungsmerkmal__${entry.id}__NutzungsartV`}>
                                                <AutoComplete
                                                    className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                    options={enumerationValuesContent.sub_com_Nutzungsart?.map(
                                                        (option, index) => ({value: option, key: index})
                                                    )}
                                                    filterOption={(inputValue, option) =>
                                                        option.value
                                                            .toUpperCase()
                                                            .indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    onChange={handleSelectChange} // Add your change handler if needed
                                                >
                                                    <TextArea
                                                        placeholder="Nutzungsart"
                                                        autoSize={true}
                                                        className="textInput"
                                                    />
                                                </AutoComplete>

                                            </Form.Item>
                                        </div>
                                        <div
                                            className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                            <Form.Item label={`Ausstattungsbeschreibung: `}
                                                       name={`Gebaeude_AA__Ausstattungsmerkmal__${entry.id}__Ausstattungsbeschreibung`}>
                                                <TextArea autoSize={true} className="textAreBes1"/>
                                            </Form.Item>
                                        </div>
                                    </Card>
                                ))}
                                <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Button
                                        className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} ml-auto  text-[12px] md:text-[14px] m-[20px] button_new_component rounded-[30px] border-none`}
                                        onClick={addCard} id={"button_new_component"}>Neues Ausstattungsmerkmal
                                        hinzufügen</Button>
                                </div>
                            </div>
                        )}
                        {(Beurteilung || objektID == "allgemein") && (
                            <div id="beurteilung" className="scrollable-section mt-[20px] mb-[20px]">
                                <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Beurteilung</h3>

                                <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Beurteilung Gebäude und Außenanlagen"
                                       name='Gebaeude_AA__Beurteilung_GebAA'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Beurteilung_GebAA', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Beurteilung_GebAA', 'Beurteilung Gebäude und Außenanlagen', 'Gebäude und Außenanlage')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                        <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Beurteilung Miete und Kosten" name='Gebaeude_AA__Beurteilung_MuK'>
                                <TextArea autoSize={true} className="textInput"
                                          ref={(element) => handleRef('Gebaeude_AA__Beurteilung_MuK', element)}/>
                            </Form.Item>
                            <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                    onClick={() => handleOpenTextbausteine('Gebaeude_AA__Beurteilung_MuK', 'Beurteilung Miete und Kosten', 'Miete und Kosten')}
                                    style={{float: 'right'}}><FileTextOutlined/></Button>

                        </div>

                    </div>
                            )}
                </div>
                </Form>


                )
            }
</div >
)
;
}
;

export default Gebaeude;
