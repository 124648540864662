import React, { useState, useEffect } from "react";
import {Table, Button, Space, Popconfirm, message, Modal} from "antd";
import {EditOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import { useSwitch } from "./SwitchContext";
import {getToken} from "../helpers";
import {getAPIurl} from "../helpers/formHelpers";
import {useNavigate} from "react-router-dom";


const UserAdministration: React.FC = () => {
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch(`${getAPIurl()}/users?populate=role`, {
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error("Error fetching users:", error);
            message.error("Fehler beim Abrufen der Benutzer.");
        }
    };

    const handleEdit = (userId) => {
        navigate(`/nutzerbearbeiten/${userId}`);
    };
    const handleAddUser = () => {
        Modal.confirm({
            title: 'Möchten Sie einen neuen Benutzer anlegen?',
            onOk() {
                navigate('/nutzerbearbeiten');
            },
            onCancel() {
                console.log('Abgebrochen');
            },
            okText: 'Ja',
            cancelText: 'Nein',
        });
    };
    const handleDelete = async (userId) => {
        try {
            await fetch(`${getAPIurl()}/users/${userId}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`
                }
            });
            message.success("Benutzer erfolgreich gelöscht.");
            fetchUsers(); // Aktualisiere die Liste nach dem Löschen
        } catch (error) {
            console.error("Error deleting user:", error);
            message.error("Fehler beim Löschen des Benutzers.");
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "username",
            key: "username"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Aktionen",
            key: "actions",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Button icon={<EditOutlined />} onClick={() => handleEdit(record.id)}>Bearbeiten</Button>
                        <Popconfirm
                            title="Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?"
                            onConfirm={() => handleDelete(record.id)}
                            okText="Ja"
                            cancelText="Nein"
                        >
                            {/*{record.role.type != "administrator" && <Button icon={<DeleteOutlined />} danger>Löschen</Button>} */}
                        </Popconfirm>
                    </Space>
                );
            }
        }
    ];

    return (
        <div style={{ padding: '20px' }}
             className={`lg:w-[84%] w-[90%] ml-auto mr-auto rounded-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <h2 className={`font-bold mb-[20px] text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Nutzerverwaltung</h2>
            <Button type="primary" icon={<PlusOutlined />} className={`auftragBtn rounded-full LinkHover`} onClick={handleAddUser}>Neuen Benutzer erstellen</Button>
            <Table columns={columns} dataSource={users} rowKey="id" />

        </div>
    );
}

export default UserAdministration;
